import React, { useState } from "react";
import LogoSweeven from "../../resources/images/logo_sweeven_dark.svg";
import { useSelector } from "react-redux";
import {
  ListItem,
  Drawer,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LanIcon from "@mui/icons-material/Lan";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BeeBetterIcon } from "../../resources/UIIcons";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import LinkIcon from "@mui/icons-material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import TollIcon from "@mui/icons-material/Toll";

export default function Navigator(props) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  let navigate = useNavigate();

  const INITIAL_VALUE = [
    {
      id: "home",
      order: Number(),
      name: t("home"),
      icon: <HomeIcon />,
      toRoute: "/home",
      active: true,
      selected: Boolean(),
    },
    {
      id: "autoShipping",
      order: 1,
      name: t("autoShipping"),
      icon: <LocalShippingIcon />,
      toRoute: "/autoShipping",
      active: false,
      selected: Boolean(),
    },
    {
      id: "linkGenerator",
      order: 2,
      name: t("linkGenerator"),
      icon: <LinkIcon />,
      toRoute: "/promotionallinks",
      active: false,
      selected: Boolean(),
    },
    {
      id: "genealogy",
      order: 3,
      name: t("genealogy"),
      icon: <LanIcon />,
      toRoute: "/genealogy",
      active: true,
      selected: false,
      // childrens: [
      //   {
      //     id: "genealogy-network",
      //     name: t("network"),
      //     toRoute: "/genealogy",
      //     active: false,
      //   },
      // ],
    },
    {
      id: "commissions",
      order: 4,
      name: t("commissions"),
      icon: <TollIcon />,
      toRoute: "/commissions",
      active: true,
      selected: false,
      // childrens: [
      //   {
      //     id: "commissions-weekly",
      //     name: t("weeklyCommissions"),
      //     toRoute: "commissions/weekly",
      //     active: false,
      //   },
      // ],
    },
    {
      id: "beeBetter",
      order: 5,
      name: "Bee Better",
      icon: <BeeBetterIcon />,
      toRoute: "",
      active: true,
      selected: Boolean(),
      externalLink: `https://beebetter.sweeven.life?dealerId=${user.dealerIdentifier}`,
    },
    {
      id: "enrollment",
      order: 6,
      toRoute: "/dealer/signup",
      name: t("enrollDealer"),
      icon: <PersonAddAlt1Icon />,
      active: true,
      selected: Boolean(),
    },
    {
      id: "externalChia",
      order: 99,
      name: t("goToCHIA"),
      icon: <LinkIcon />,
      secondaryIcon: <OpenInNewIcon />,
      active: true,
      selected: Boolean(),
      externalLink: "https://chia-acel.club.hotmart.com/login",
    },
  ];

  const [menu, setMenu] = useState(INITIAL_VALUE);

  const handleItemSelected = (item) => {
    if (item.childrens) {
      setMenu((prev) => {
        let result = prev.find((menu) => menu.id === item.id);
        result.selected = !result.selected;
        let filtered = prev.filter((x) => x.id !== item.id);
        filtered.push(result);
        return filtered;
      });
    } else if (item.externalLink) {
      window.location.href = item.externalLink;
    } else {
      navigate(item.toRoute);
    }
  };

  const item = {
    py: "2px",
    px: 3,
    color: "rgba(255, 255, 255,0.8)",
    "&:hover, &:focus": {
      bgcolor: "rgba(255, 255, 255, 0.08)",
    },
  };

  const itemCategory = {
    boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
    py: 1.5,
    px: 3,
  };

  const news = (open) => {
    return {
      py: 2,
      px: 3,
      pb: open ? 0 : 2.5,
      "&:hover, &:focus": { "& svg": { opacity: 1 } },
    };
  };

  const { ...other } = props;

  const renderArrow = (selected, childrens) => {
    if (childrens) {
      return (
        <KeyboardArrowDown
          sx={{
            mr: -1,
            opacity: 0,
            transform: selected ? "rotate(-180deg)" : "rotate(0)",
            transition: "0.2s",
          }}
        />
      );
    } else {
      return null;
    }
  };

  const renderMenu = () => {
    return menu
      .filter((menu) => menu.active)
      .sort((a, b) => a.order - b.order)
      .map((item) => {
        return (
          <Box
            key={item.id}
            sx={{
              color: "rgba(255, 255, 255,0.8)",
              bgcolor: item.selected ? "rgba(99, 99, 90, 0.2)" : null,
              pb: item.selected ? 2 : 0,
            }}
          >
            <ListItemButton
              sx={{ ...news(item.selected) }}
              onClick={() => {
                handleItemSelected(item);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
              {item.secondaryIcon && (
                <ListItemIcon>{item.secondaryIcon}</ListItemIcon>
              )}
              {renderArrow(item.selected, item.childrens)}
            </ListItemButton>
            {item.selected &&
              item.childrens.map((children) => (
                <ListItemButton
                  key={children.id}
                  onClick={() => {
                    handleItemSelected(children);
                  }}
                  sx={{
                    py: 0,
                    minHeight: 32,
                    color: "rgba(255,255,255,.8)",
                    ml: 6,
                  }}
                >
                  <ListItemText
                    primary={children.name}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  ></ListItemText>
                </ListItemButton>
              ))}
          </Box>
        );
      });
  };

  // const renderChildrens = (childrens) => {
  //   if (childrens) {
  //     return childrens
  //       .filter((child) => child.active === true)
  //       .map((children) => {
  //         return (
  //           <ListItemButton
  //             key={children.id}
  //             sx={{ py: 0, minHeight: 32, color: "rgba(255,255,255,.8)" }}
  //           >
  //             <ListItemIcon sx={{ color: "inherit" }}>
  //               {children.icon}
  //             </ListItemIcon>
  //             <ListItemText
  //               primary={children.name}
  //               primaryTypographyProps={{ fontSize: 14, fontWeight: "medium" }}
  //             ></ListItemText>
  //           </ListItemButton>
  //         );
  //       });
  //   } else {
  //     return null;
  //   }
  // };

  return (
    <Drawer variant="permanent" {...other}>
      <List component="nav">
        <ListItem
          sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
          onClick={() => {
            navigate("/home");
          }}
        >
          <img
            alt={"logo"}
            style={{
              width: "210px",
              height: "auto",
              margin: "auto",
            }}
            src={LogoSweeven}
          />
        </ListItem>
        {renderMenu()}
        {/* {mainMenu
          .filter((mMenu) => mMenu.active === true)
          .map((menu) => {
            return (
              <Box key={menu.id}>
                <ListItem sx={{ ...item, ...itemCategory }}>
                  <ListItemIcon>{menu.icon}</ListItemIcon>
                  <ListItemText
                    onClick={() => {
                      if (menu.externalLink) {
                        window.location.href = menu.externalLink;
                      } else {
                        navigate(menu.toRoute);
                      }
                    }}
                  >
                    {menu.name}
                  </ListItemText>
                </ListItem>
                {menu.childrens && renderChildrens(menu.childrens)}
              </Box>
            );
          })}

        {categories.map(({ id, icon, children }) => (
          <Box key={id}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemIcon sx={{ color: "#fff" }}>{icon}</ListItemIcon>
              <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
            </ListItem>

            {children.map(({ id: childId, icon, active, toRoute }) => (
              <ListItem disablePadding key={childId + "sds"}>
                <ListItemButton
                  selected={active}
                  sx={item}
                  onClick={() => {
                    navigate(`/${toRoute}`);
                  }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        ))} */}
      </List>
    </Drawer>
  );
}
