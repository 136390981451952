import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button,
  Container,
  CssBaseline,
  Box,
  Typography,
} from "@mui/material";

export default function AddressShippingComponent(props) {
  const { t } = useTranslation();
  const [data, setData] = useState({
    firstName: String(),
    lastName: String(),
    address1: String(),
    address2: String(),
    country: String(),
    city: String(),
    stateProvince: String(),
    zipCode: String(),
  });

  useEffect(() => {
    if (props.address) {
      setData(props.address);
    }
  });

  const handleTextChange = (e) => {
    const value = e.target.value;

    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onComplete("shippingAddress", data);
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Box
        sx={{
          m: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">{"Dirección de envio"}</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="firstName"
                id="firstName"
                label={t("firtsName")}
                value={data.firstName}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="lastName"
                id="lastName"
                label={t("lastName")}
                value={data.lastName}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="address1"
                id="address1"
                label={t("address1")}
                value={data.address1}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="address2"
                id="address2"
                label={t("address2")}
                value={data.address2}
                onChange={handleTextChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="country"
                id="country"
                label={t("country")}
                value={data.country}
                onChange={handleTextChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="city"
                id="city"
                label={t("city")}
                value={data.city}
                onChange={handleTextChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="stateProvince"
                id="stateProvince"
                label={t("stateProvince")}
                value={data.stateProvince}
                onChange={handleTextChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="zipCode"
                id="zipCode"
                label={t("zipCode")}
                value={data.zipCode}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid item xs={612}>
              <Button fullWidth type="submit" variant="contained">
                {t("next")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
