import { AuthTypes } from "../types/AuthTypes";

const clearError = {
  errorTitle: null,
  errorMessage: null,
};

export const AuthReducer = (state = {}, action) => {
  switch (action.type) {
    case AuthTypes.signIn:
      return {
        ...state,
        ...action.payload,
        ...clearError,
      };

    case AuthTypes.nameChanged:
      return {
        ...state,
        user: action.payload,
      };

    case AuthTypes.updateInfo:
      return {
        ...state,
        ...action.payload.data,
        ...clearError,
      };

    case AuthTypes.error:
      return {
        errorTitle: "Error",
        errorMessage: "Email / Contraseña incorrecta",
      };

    case AuthTypes.signOut:
      return {};

    default:
      return state;
  }
};
