import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  CssBaseline,
  Box,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FrequencyType } from "../models/enums/FrequencyType";
import { AutoShippingType } from "../types/AutoShippingType";

export const ShippingFrequencyComponent = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const autoshipping = useSelector((state) => state.autoshipping);
  const intervals = [
    FrequencyType.weekly,
    FrequencyType.biweekly,
    FrequencyType.monthly,
  ];
  // const [data, setData] = useState({
  //   startedDate: props.interval
  //     ? new Date(props.interval.startedDate)
  //     : new Date(new Date().setDate(new Date().getDate() + 1)),
  //   receive: props.interval ? props.interval.receive : FrequencyType.monthly,
  // });

  useImperativeHandle(ref, () => ({
    onClick() {
      document.getElementById("userForm").submitButton.click();
    },
  }));

  const handleFrequencyChange = (e) => {
    const value = e.target.value;
    dispatch({
      type: AutoShippingType.setData,
      payload: {
        ...autoshipping,
        interval: {
          ...autoshipping.interval,
          [e.target.name]: value,
        },
      },
    });
  };

  const handleDateChange = (value) => {
    dispatch({
      type: AutoShippingType.setData,
      payload: {
        ...autoshipping,
        interval: {
          ...autoshipping.interval,
          startedDate: value.toDate().toISOString(),
        },
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onComplete();
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Box
        id="userForm"
        component="form"
        onSubmit={handleSubmit}
        sx={{ mt: 1 }}
      >
        <Box
          sx={{
            m: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2} m={2}>
            <Grid item xs={12}>
              <Typography variant="h5">{"Fecha de envio"}</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  required
                  label={t("startedDate")}
                  type="date"
                  inputFormat="DD/MM/YYYY"
                  id="startedDate"
                  value={new Date(autoshipping.interval.startedDate)}
                  onChange={handleDateChange}
                  minDate={
                    new Date(new Date().setDate(new Date().getDate() + 1))
                  }
                  maxDate={
                    new Date(new Date().setDate(new Date().getDate() + 30))
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  required
                  disablePast={true}
                  ActionBar={null}
                  label={t("startedDate")}
                  type="date"
                  inputFormat="DD/MM/YYYY"
                  id="startedDate"
                  value={data.startedDate}
                  onChange={handleDateChange}
                  minDate={
                    new Date(new Date().setDate(new Date().getDate() + 1))
                  }
                />
              </LocalizationProvider> */}

              {/* <TextField name="firstName" id="firstName" required fullWidth /> */}
            </Grid>
            {/* <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="sendEvery"
                id="sendEvery"
                label={t("sendEvery")}
                value={data.sendEvery}
                onChange={handleTextChange}
                sx={{ width: "150px" }}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: "150px" }}>
                <InputLabel id="receive">{t("receive")}</InputLabel>
                <Select
                  required
                  labelId="receive"
                  id="receive"
                  name="receive"
                  disabled={true}
                  label={t("receive")}
                  value={autoshipping.interval.receive}
                  onChange={handleFrequencyChange}
                >
                  {intervals.map((interval) => {
                    return (
                      <MenuItem key={interval} value={interval}>
                        {t(interval)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <input type="submit" style={{ display: "none" }} name="submitButton" />
      </Box>
    </Container>
  );
});
