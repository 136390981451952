import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, CircularProgress, Typography, Button, Box } from "@mui/material";
import { log } from "../../helpers/Logger";
import { Payment } from "@mercadopago/sdk-react";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { PortableWifiOffSharp } from "@mui/icons-material";
initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);

export default function PaymentBricksComponent(props) {
  const { t } = useTranslation();
  const [ready, setReady] = useState(false);
  const customization = {
    paymentMethods: {
      atm: "all",
      ticket: "all",
      creditCard: "all",
      debitCard: "all",
      mercadoPago: "all",
    },
  };

  useEffect(() => {}, []);

  const onSubmit = async ({ selectedPaymentMethod, formData }) => {
    // callback llamado al hacer clic en el botón enviar datos
    props.onSubmit({ selectedPaymentMethod, formData });
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  const onError = async (error) => {
    // callback llamado para todos los casos de error de Brick
    console.log(error);
  };

  const onReady = async (r) => {
    setReady(true);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">{t("paymentMethod")}</Typography>
      </Grid>
      {!ready && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      <Grid item xs={12}>
        <Payment
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
          customization={customization}
          initialization={{
            amount: props.total,
            preferenceId: props.preference,
          }}
        />
      </Grid>
    </Grid>
  );
}
