import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import SweevenLogo from "../../resources/images/logo_sweeven_light.svg";
import BaseHomePage from "../General/BaseHomePage";
import PaperBaseComponent from "../../components/general/PaperBaseComponent";
import {
  Box,
  Container,
  CssBaseline,
  Typography,
  Grid,
  Stack,
  Link,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import UIButton from "../../components/general/UIButton";
import { AddressViewComponent } from "../../components/general/AddressViewComponent";
import { EnrollKitsComponent } from "../../components/EnrollKitsComponent";
import { UserFormComponent } from "../../components/UserFormComponent";
import { EmailValidationComponent } from "../../components/Authentication/EmailValidationComponent";
import UIStepper from "../../components/general/UIStepper";
import {
  registerNewDealerService,
  validateEmailService,
  completePaymentService,
} from "../../APIServices/UserAPIService";
import { log, logError } from "../../helpers/Logger";
import PaymentBricksComponent from "../../components/payment/PaymentBricksComponent";
import StatusPaymentComponent from "../../components/payment/StatusPaymentComponent";

import SendLinkComponent from "../../components/Authentication/SendLinkComponent";
import { SignUpType } from "../../types/SignUpType";

function Copyright() {
  let env = String();
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    env = process.env.REACT_APP_ENVIRONMENT;
  }
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://sweeven.life/">
        {process.env.REACT_APP_NAME}
      </Link>
      {` ${new Date().getFullYear()}`}
      {` ${env}`}
    </Typography>
  );
}

export default function RegisterPage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.signup);
  const user = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();
  const [type, setType] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(Number());
  const steps = useMemo(
    () => [
      t("validateEmail"),
      t("generalData"),
      t("address"),
      t("billing"),
      t("starterKit"),
      t("check"),
      t("payment"),
      t("resume"),
    ],
    [t]
  );
  const [viewData, setViewData] = useState({
    needData: Boolean(),
    loading: Boolean(),
    success: Number(),
    continue: true,
    error: null,
  });
  const [activationUrl, setActivationUrl] = useState(null);
  const [preference, setPreference] = useState(null);
  const [paymentResult, setPaymentResult] = useState(null);
  const [validate, setValidate] = useState(Boolean());
  // const [data, setData] = useState({
  //   email: String(),
  //   confirmEmail: String(),
  //   starterKit: null,
  // });
  const [dealer, setDealer] = useState(null);

  const [goToUp, setGoToUp] = useState(true);
  const ref = useRef();

  const validateEmail = useCallback(async () => {
    try {
      setValidate(Boolean());
      setViewData(() => {
        return {
          needData: Boolean(),
          loading: true,
          error: null,
        };
      });

      let response = await validateEmailService(data.email);
      log(response.code);
      if (response.code === 0) {
        setViewData((prev) => {
          return {
            ...prev,
            error: null,
            loading: Boolean(),
            continue: true,
          };
        });
        setCurrentIndex(currentIndex + 1);
      } else if (response.code === 1) {
        if (response.error) {
          setViewData((prev) => {
            return {
              ...prev,
              error: response.error,
              loading: Boolean(),
              continue: true,
            };
          });
        }
      } else if (response.code === 3) {
        setActivationUrl(response.data);
        setCurrentIndex(currentIndex + 1);
        setViewData((prev) => {
          return {
            ...prev,
            error: null,
            loading: Boolean(),
            continue: true,
          };
        });
        // setRedirect(
        //   `${response.data}&email=${data.email}&sponsorName=${dealer.name}&sponsorId=${dealer.identifier}&type=enroll`
        // );
      } else {
        logError(response);
      }
    } catch (error) {
      logError(error);
    }
  }, [data, currentIndex]);

  useEffect(() => {
    log(user);
    // if (user.identifier) {
    //   setType(2);
    // }

    if (type == null) {
      if (user.identifier) {
        setType(2);
        setDealer({
          identifier: user.dealerIdentifier,
          name: `${user.firstName} ${user.lastName}`,
        });
      } else {
        setType(0);
        setDealer({
          identifier: searchParams.get("dealerId"),
          name: searchParams.get("dealerName"),
        });
      }
    }

    // if (type === 2 && dealer === null) {
    // } else if (type === 0 && dealer === null) {
    //   setDealer({
    //     identifier: searchParams.get("dealerId"),
    //     name: searchParams.get("dealerName"),
    //   });
    // }

    if (steps[currentIndex] === t("validateEmail")) {
      if (validate) {
        validateEmail();
      }
    }

    if (steps[currentIndex] === t("starterKit")) {
      if (data.starterKit.identifier !== String()) {
        handleForward(true);
      } else {
        handleForward(false);
      }
    }
  }, [
    validateEmail,
    validate,
    currentIndex,
    data,
    type,
    user,
    searchParams,
    t,
    steps,
    dealer,
  ]);

  const handleForward = (option) => {
    setViewData((prev) => {
      return {
        ...prev,
        continue: option,
      };
    });
  };

  const handleCreationResponse = useCallback(
    async (response) => {
      setViewData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      setPreference(response.data);
      setCurrentIndex(currentIndex + 1);
    },
    [currentIndex]
  );

  const createUserService = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });
    setGoToUp(Boolean());
    try {
      let aux = data;
      Object.keys(data).forEach((key) => {
        console.log(typeof data[key] === "object");
        if (Object.keys(data[key]).includes("value")) {
          aux = {
            ...aux,
            [key]: data[key].value,
          };
        }
      });
      let response = await registerNewDealerService(
        {
          ...aux,
          sponsorIdentifier: dealer.identifier,
          activationUrl,
        },
        type
      );
      if (response.code === 0) {
        handleCreationResponse(response);
      } else {
        if (response.error) {
          setViewData((prev) => {
            return { ...prev, loading: false, error: response.error };
          });
        }
      }
    } catch {}
  }, [data, dealer, type, handleCreationResponse, activationUrl]);

  const compleRegisterService = useCallback(
    async (paymentData) => {
      setViewData({
        needData: Boolean(),
        loading: true,
      });
      setGoToUp(Boolean());
      try {
        let response = await completePaymentService(
          {
            ...data,
            ...paymentData,
          },
          type
        );
        if (response.code === 0) {
          setPaymentResult(response.data);
          setCurrentIndex(currentIndex + 1);
          dispatch({ type: SignUpType.clear });
        } else {
          if (response.error) {
            setViewData((prev) => {
              return { ...prev, error: response.error };
            });
          }
        }
      } catch {}
    },
    [data, type, currentIndex, dispatch]
  );

  const onComplete = async (identifier, newData) => {
    setViewData((prev) => {
      return {
        ...prev,
        continue: true,
        loading: false,
      };
    });
    // setData((prev) => {
    //   return {
    //     ...prev,
    //     ...newData,
    //   };
    // });

    if (identifier === "emailValidation") {
      setValidate(true);
    } else {
      if (steps[currentIndex + 1] === t("starterKit")) {
        setViewData((prev) => {
          return {
            ...prev,
            continue: Boolean(),
          };
        });
      }
      setCurrentIndex(currentIndex + 1);
    }
  };

  const onSubmitPayment = (payment) => {
    console.log(payment);
    if (
      payment.selectedPaymentMethod === "onboarding_credits" ||
      payment.selectedPaymentMethod === "wallet_purchase"
    ) {
      window.location.href = "https://sweeven.life";
    } else {
      compleRegisterService(payment);
    }
  };

  const handleAddressChange = (name, value) => {
    dispatch({
      type: SignUpType.handleAddressesChange,
      payload: { name, value },
    });
    setCurrentIndex(currentIndex + 1);
  };

  const renderView = () => {
    switch (steps[currentIndex]) {
      case t("validateEmail"):
        return (
          <EmailValidationComponent
            ref={ref}
            onComplete={onComplete}
            emailDisabled={true}
          />
        );
      case t("generalData"):
        return (
          <UserFormComponent
            ref={ref}
            onComplete={onComplete}
            type={type}
            // data={{ email: data.email, confirmEmail: data.confirmEmail }}
            emailDisabled={true}
          />
        );
      case t("address"):
        return (
          <AddressViewComponent
            ref={ref}
            billing={false}
            onComplete={handleAddressChange}
            customKey={"shippingAddress"}
            userInfo={{
              firstName: data.firstName.value,
              lastName: data.lastName.value,
            }}
            title={"Dirección de envio:"}
          />
        );
      case t("billing"):
        return (
          <AddressViewComponent
            ref={ref}
            billing={true}
            address={data.shippingAddress}
            customKey={"billingAddress"}
            onComplete={handleAddressChange}
            title={"Dirección de facturación:"}
          />
        );
      case t("starterKit"):
        return <EnrollKitsComponent ref={ref} onComplete={onComplete} />;
      case t("check"):
        if (goToUp) {
          window.scrollTo(0, 0);
        } else {
          window.scrollTo(0, document.body.scrollHeight);
        }
        return renderResume();
      case t("payment"):
        if (type === 2) {
          return (
            <SendLinkComponent
              dealerName={data.firstName.value}
              sponsorName={user.firstName}
              preference={preference}
              email={data.email.value}
              phone={
                data.phone.value.code.replace("+", "") + data.phone.value.number
              }
            />
          );
        } else {
          if (viewData.loading) {
            return <CircularProgress />;
          } else {
            return (
              <PaymentBricksComponent
                onSubmit={onSubmitPayment}
                total={data.starterKit.price}
                preference={preference}
              />
            );
          }
        }

      case t("resume"):
        return (
          <StatusPaymentComponent
            paymentIdentifier={paymentResult.identifier}
          />
        );

      default:
        return null;
    }
  };

  const renderButton = () => {
    let text = t("next");
    let button = (
      <UIButton
        fullWidth
        disabled={!viewData.continue}
        type="submit"
        variant="contained"
        loading={viewData.loading}
        title={text}
        onClick={() => {
          if (steps[currentIndex] === t("check")) {
            createUserService();
          } else {
            ref.current.onClick();
          }
        }}
      />
    );

    if (steps[currentIndex] !== t("resume")) {
      if (steps[currentIndex] !== t("payment")) {
        return (
          <Grid container spacing={1}>
            {currentIndex > 0 && (
              <Grid item xs={6}>
                <UIButton
                  fullWidth
                  variant="outlined"
                  title={t("back")}
                  onClick={() => {
                    setCurrentIndex(currentIndex - 1);
                  }}
                />
              </Grid>
            )}
            <Grid item xs={currentIndex > 0 ? 6 : 12}>
              {button}
            </Grid>
          </Grid>
        );
      }
    } else {
      if (type === Number()) {
        return (
          <Grid container>
            <Grid item xs={12}>
              <UIButton
                fullWidth
                variant="contained"
                title={t("Ir a sweeven")}
                onClick={() => {
                  window.location.href = "http://sweeven.life";
                }}
              />
            </Grid>
          </Grid>
        );
      }
    }
  };

  const renderResume = () => {
    let shipping = null;
    if (Number(data.starterKit.price) <= 2000) {
      shipping = (
        <Typography variant="h5">{`${t(
          "shipping"
        )}: ${"70"}.00 MXN`}</Typography>
      );
    }
    return (
      <Grid container spacing={1}>
        <Grid item sm={8}>
          <UserFormComponent user={data} disabled />
          <AddressViewComponent
            address={data.shippingAddress}
            disabled
            title={"Dirección:"}
            customKey={"shippingReview"}
          />
          <AddressViewComponent
            address={data.billingAddress}
            disabled
            title={"Dirección de facturación:"}
            customKey={"billingAddressReview"}
          />
        </Grid>
        <Grid item sm={4}>
          <Box>
            <Box
              sx={{
                width: { xs: "80vw", sm: "30vw" },
                height: "auto",
              }}
              component={"img"}
              src={data.starterKit.imageUrl}
            />
            {shipping}
            <Typography variant="h5">{`${t("total")}: ${
              data.starterKit.price
            } MXN`}</Typography>
          </Box>
        </Grid>
        {type === 0 && (
          <Grid item xs={12}>
            <Typography
              variant="body"
              color="text.secondary"
              align="center"
              fontWeight="bold"
            >
              {`Al hacer clic en "${t("next")}", estás aceptando los `}
              <Link
                color="inherit"
                href="https://sweeven.life/policies/terms-of-service"
                target="_blank"
              >
                {`Términos y Condiciones`}
              </Link>
              {" , las "}
              <Link
                color="inherit"
                href="https://sweeven.life/policies/privacy-policy"
                target="_blank"
              >
                {`Políticas de Privacidad`}
              </Link>
              {" y el "}
              <Link
                color="inherit"
                href="https://cdn.shopify.com/s/files/1/0555/8003/3192/files/CONTRATO_DISTRIBUCION_SWEEVEN_v4_1.pdf?v=1669740364"
                target="_blank"
              >
                {`Contrato de Asociado Sweeven.`}
              </Link>
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderDealerInfo = () => {
    let view = null;
    if (dealer) {
      if (dealer.identifier !== null && dealer.identifier !== "null") {
        view = (
          <Stack spacing={1}>
            <Typography variant="h5">{"TU REPRESENTANTE"}</Typography>
            <Box>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                {dealer.identifier}
              </Typography>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                {dealer.name}
              </Typography>
            </Box>
          </Stack>
        );
      }
    }
    return view;
  };

  const renderError = () => {
    if (viewData.error) {
      return (
        <Alert severity="error">
          <AlertTitle>{viewData.error.title}</AlertTitle>
          {viewData.error.message}
        </Alert>
      );
    }
  };

  const renderHeader = () => {
    if (type === 2) {
      return (
        <Box>
          <Typography variant="h5">{"FELICIDADES!"}</Typography>
          <Typography>
            {"Estás a unos pasos de hacer crecer tu negocio"}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{ width: 230, height: "auto", m: 1 }}
            src={SweevenLogo}
          />
          <Typography variant="h5">{"¡BIENVENIDO!"}</Typography>
          <Typography variant="h5">{"VAMOS A EMPEZAR!"}</Typography>
          <Typography>
            {
              "¡Nos complace de que hayas decidido unirte a nosotros! Completa la información solicitada a continuación mientras nuestro sistema te guía a través del proceso de inscripción. ¡Comienza tu viaje hacia la libertad financiera!"
            }
          </Typography>
          {renderDealerInfo()}
        </Box>
      );
    }
  };

  const renderPage = () => {
    let view = (
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {renderHeader()}

        <UIStepper index={currentIndex} steps={steps} />
        {renderView()}
        {renderError()}
        {renderButton()}
      </Box>
    );

    if (props.user) {
      return (
        <BaseHomePage>
          <PaperBaseComponent sx={{ m: 2 }}>{view}</PaperBaseComponent>
        </BaseHomePage>
      );
    } else {
      return (
        <Container component="main">
          {view}
          <Box component="footer" sx={{ p: 2 }}>
            <Copyright />
          </Box>
        </Container>
      );
    }
  };

  return renderPage();
}
