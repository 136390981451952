import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SweevenLogo from "../../resources/images/logo_sweeven_light.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  TextField,
  Container,
  CssBaseline,
  Typography,
  AlertTitle,
  Alert,
} from "@mui/material";

import UIButton from "../../components/general/UIButton";
import { signInAction } from "../../actions/AuthenticationAction";

export default function ResetPage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(Boolean());
  const errorTitle = useSelector((state) => state.auth.errorTitle);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const [data, setData] = useState({
    email: String(),
    password: String(),
  });

  const { email, password } = data;

  useEffect(() => {
    if (errorTitle) {
      setLoading(Boolean());
    }
  }, [errorTitle]);

  const handleTextChange = (e) => {
    const value = e.target.value;

    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    dispatch(signInAction(email, password));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{ width: 210, height: "auto", m: 1, mb: 5 }}
          src={SweevenLogo}
        />
        {/* <Typography variant="h3">Sign in</Typography> */}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {errorTitle && (
            <Alert severity="error">
              <AlertTitle>{errorTitle}</AlertTitle>
              {errorMessage}
            </Alert>
          )}
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {t("recoveryPassword")}
          </Typography>
          <TextField
            onChange={handleTextChange}
            value={password}
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("password")}
            type="password"
            id="password"
            autoComplete="password"
          />

          <TextField
            onChange={handleTextChange}
            value={password}
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("password")}
            type="password"
            id="password"
            autoComplete="password"
          />

          <UIButton
            fullWidth
            type="submit"
            variant="contained"
            loading={loading}
            title={t("sendEmail")}
          />
        </Box>
      </Box>
    </Container>
  );
}
