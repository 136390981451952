import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BaseHomePage from "../General/BaseHomePage";
import {
  Grid,
  Button,
  AppBar,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PaperBaseComponent from "../../components/general/PaperBaseComponent";
import { getAutoshippingByIdentifierService } from "../../APIServices/AutoShippingAPIService";

export default function AutoShippingIndexPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userIdentifier = useSelector((state) => state.auth.id);
  const [autoshippings, setAutoshippings] = useState(null);
  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });

  const getAutoshippingInvoke = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await getAutoshippingByIdentifierService(userIdentifier);
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });

    if (response.code === 0) {
      setAutoshippings(response.data);
    }
  }, []);

  const getDistributorPrice = (data) => {
    return data.reduce((total, item) => total + item.distributorPrice, 0);
  };

  useEffect(() => {
    if (viewData.needData) {
      getAutoshippingInvoke();
    }
  }, [viewData.needData, getAutoshippingInvoke]);

  const renderTable = () => {
    let view = null;
    if (autoshippings) {
      if (autoshippings.length > 0) {
        view = (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t("address")}</TableCell>
                  <TableCell align="right">{t("price")}</TableCell>
                  <TableCell align="right">{t("day")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {autoshippings.map((item) => (
                  <TableRow
                    key={item.identifier}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      navigate("detail", { state: { data: item } });
                    }}
                  >
                    <TableCell align="left" component="th" scope="row">
                      {item.shippingAddress.address1}
                    </TableCell>
                    <TableCell align="right">
                      {`${getDistributorPrice(item.products)} MXN`}
                    </TableCell>
                    <TableCell align="right">
                      {item.interval.startedDate.split("-")[2]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    } else {
      view = <CircularProgress />;
    }
    return view;
  };

  const renderView = () => {
    return <React.Fragment>{renderTable()}</React.Fragment>;
  };

  return (
    <BaseHomePage>
      <PaperBaseComponent>
        <AppBar
          position="static"
          color="default"
          align="center"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={0} alignItems="center">
              {/* <Grid item>
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </Grid> */}
              {/* <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by email address, phone number, or user UID"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: "default" },
                  }}
                  variant="standard"
                />
              </Grid> */}

              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("create");
                  }}
                  sx={{ mr: 1 }}
                >
                  {t("newAutoshipping")}
                </Button>
              </Grid>
              {/* <Grid item>
                <Tooltip title="Reload">
                  <IconButton>
                    <RefreshIcon color="inherit" sx={{ display: "block" }} />
                  </IconButton>
                </Tooltip>
              </Grid> */}
            </Grid>
          </Toolbar>
        </AppBar>
        {renderView()}
      </PaperBaseComponent>
    </BaseHomePage>
  );
}
