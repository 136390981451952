import React, { forwardRef, useImperativeHandle } from "react";
import {
  Grid,
  Box,
  Container,
  CssBaseline,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";

export const AutoShippingOrderTotalComponent = forwardRef((props, ref) => {
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    onClick() {
      document.getElementById("userForm").submitButton.click();
    },
  }));

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onComplete();
  };

  const renderTable = () => {
    return (
      <Stack spacing={2}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{"SKU"}</TableCell>
                <TableCell align="center">{t("name")}</TableCell>
                <TableCell align="center">{t("price")}</TableCell>
                <TableCell align="center">{t("calification")}</TableCell>
                <TableCell align="center">{t("quantity")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.products.map((product) => (
                <TableRow
                  key={product.sku}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {product.sku}
                  </TableCell>
                  <TableCell align="center">{product.name}</TableCell>
                  <TableCell align="center">
                    {product.distributorPrice}
                  </TableCell>
                  <TableCell align="center">
                    {product.calificationPoints}
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{product.quantity}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    );
  };

  const renderTotalView = () => {
    let totalPrice = props.products.reduce(
      (total, product) => total + product.distributorPrice * product.quantity,
      0
    );
    let totalPoints = props.products.reduce(
      (total, product) => total + product.calificationPoints * product.quantity,
      0
    );
    return (
      <Grid container spacing={2} m={2}>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>{`${t(
            "totalImport"
          )}: `}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${Intl.NumberFormat("es-MX").format(
            totalPrice
          )} MXN`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>{`${t(
            "ascendet"
          )}: `}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${totalPoints} PTS`}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Box
        id="userForm"
        component="form"
        onSubmit={handleSubmit}
        sx={{ mt: 1 }}
      >
        <Box
          sx={{
            m: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {renderTable()}
            </Grid>

            <Grid item xs={12}>
              {renderTotalView()}
            </Grid>
          </Grid>
        </Box>
        <input type="submit" style={{ display: "none" }} name="submitButton" />
      </Box>
    </Container>
  );
});
