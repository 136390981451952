import { AutoShippingType } from "../types/AutoShippingType";
import ProductsList from "../products.json";
import { log } from "../helpers/Logger";
import { FrequencyType } from "../models/enums/FrequencyType";

const getProducts = (products) => {
  let result = {};
  let productsActive = ProductsList.products.filter(
    (product) => product.active
  );
  result = productsActive.map((item) => {
    return {
      ...item,
      quantity: 0,
    };
  });
  if (products) {
    products.forEach((product) => {
      result.find((inProduct) => inProduct.sku === product.sku).quantity =
        product.quantity;
    });
  }
  return result;
};

const getSelectedProducts = (aux) => {
  let productsSelected = aux.filter((item) => item.quantity > 0);
  if (productsSelected.length > 0) {
    return productsSelected;
  } else {
    return {};
  }
};

let startDate = new Date(new Date().setDate(new Date().getDate() + 1));

let INITIAL_STATE = {
  products: getProducts(),
  selectedProducts: {},
  interval: {
    startedDate: startDate.toISOString(),
    receive: FrequencyType.monthly,
  },
  shippingAddress: {},
  billingAddress: {},
  error: null,
};

export const AutoShippingReducer = (state = INITIAL_STATE, action) => {
  let product = null;
  switch (action.type) {
    case AutoShippingType.handleAddProduct:
      product = action.payload;
      let aux = state.products.filter((item) => item.sku !== product.sku);
      aux.push({
        ...product,
        quantity: product.quantity + 1,
      });
      aux.sort((a, b) => a.sku - b.sku);

      return {
        ...state,
        products: aux,
        selectedProducts: getSelectedProducts(aux),
      };

    case AutoShippingType.handleRemoveProduct:
      product = action.payload;
      if (product.quantity > 0) {
        let aux = state.products.filter((item) => item.sku !== product.sku);
        aux.push({
          ...product,
          quantity: product.quantity - 1,
        });
        aux.sort((a, b) => a.sku - b.sku);
        return {
          ...state,
          products: aux,
          selectedProducts: getSelectedProducts(aux),
        };
      } else {
        return state;
      }

    case AutoShippingType.handleSelectedAddress:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case AutoShippingType.autoshippingCreated:
      return {
        ...state,
        loading: Boolean(),
      };

    case AutoShippingType.setData:
      return action.payload;

    case AutoShippingType.error:
      return {
        ...state,
        error: action.payload,
      };

    case AutoShippingType.clean:
      return INITIAL_STATE;

    default:
      return state;
  }
};
