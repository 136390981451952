import { combineReducers, compose, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { AuthReducer } from "../reducers/AuthReducer";
import { UserReducer } from "../reducers/UserReducer";
import { AddressesReducer } from "../reducers/AddressesReducer";
import { AutoShippingReducer } from "../reducers/AutoShippingReducer";
import { SignUpReducer } from "../reducers/SignUpReducer";
import { CommissionsReducer } from "../reducers/CommissionsReducer";
import { BaseViewReducer } from "../reducers/BaseViewReducer";

const reducers = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  addresses: AddressesReducer,
  autoshipping: AutoShippingReducer,
  signup: SignUpReducer,
  commissions: CommissionsReducer,
  baseView: BaseViewReducer,
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export const store = configureStore(
  { reducer: reducers },
  composeEnhancers(applyMiddleware(thunk))
);
