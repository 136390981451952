import { BaseViewType } from "../types/BaseViewType";

export const BaseViewReducer = (state = {}, action) => {
  switch (action.type) {
    case BaseViewType.showAlert:
      return {
        type: action.type,
        title: action.title,
        message: action.message,
      };

    default:
      return state;
  }
};
