import React from "react";
import { Container, CssBaseline, Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import ConstructionIcon from "@mui/icons-material/Construction";
import { logError } from "./Logger";
import Lottie from "lottie-react";
import loading from "../resources/animations/maintenance.json";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  renderView = () => {
    const { t } = this.props;
    return (
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            m: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stack>
            <ConstructionIcon />
            <Typography>{t("weAreWorking")}</Typography>
          </Stack>
        </Box>
      </Container>
    );
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    logError(`ERROR: ${error}`);

    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    //logErrorToMyService(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          sx={{
            display: "flex",
            minHeight: "100vh",

            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1>{"Ups! Algo ha salido mal"}</h1>
          <Lottie animationData={loading} />
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
