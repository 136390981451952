import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import BaseHomePage from "../General/BaseHomePage";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
  AppBar,
  Toolbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PaperBaseComponent from "../../components/general/PaperBaseComponent";
import { getAutoshippingService } from "../../APIServices/AutoShippingAPIService";
import AddPromotionalLinksComponent from "../../components/PromotionalLinks/AddPromotionalLinksComponent";

export default function PromotionalLinksPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [promotionalLinks] = useState(null);
  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });
  // const navigate = useNavigate();
  const [data] = useState({
    email: String(),
  });

  const getAutoshippingInvoke = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await getAutoshippingService(data.email);
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });

    if (response.code === 0) {
    }
  }, [data, navigate]);

  useEffect(() => {
    if (viewData.needData) {
      getAutoshippingInvoke();
    }
  }, [viewData.needData, getAutoshippingInvoke]);

  const renderTable = () => {
    let view = null;
    if (promotionalLinks) {
      if (promotionalLinks.length > 0) {
        view = (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t("address")}</TableCell>
                  <TableCell align="right">{t("price")}</TableCell>
                  <TableCell align="right">{t("day")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {promotionalLinks.map((link) => (
                  <TableRow
                    key={link.lineitemSku}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" component="th" scope="row">
                      {link.shippingAddress.address1}
                    </TableCell>
                    <TableCell align="right">{link.count}</TableCell>
                    <TableCell align="right">
                      {Intl.NumberFormat("es-MX").format(link.amount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    } else {
      view = <CircularProgress />;
    }
    return view;
  };

  const renderView = () => {
    return <React.Fragment>{renderTable()}</React.Fragment>;
  };

  return (
    <BaseHomePage>
      <PaperBaseComponent>
        <AppBar
          position="static"
          color="default"
          align="center"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={0} wrap="nowrap">
              {/* <Grid item>
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </Grid> */}
              {/* <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by email address, phone number, or user UID"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: "default" },
                  }}
                  variant="standard"
                />
              </Grid> */}

              <Grid item xs={12} sx={{ m: 1 }}>
                <AddPromotionalLinksComponent />
              </Grid>
              {/* <Grid item>
                <Tooltip title="Reload">
                  <IconButton>
                    <RefreshIcon color="inherit" sx={{ display: "block" }} />
                  </IconButton>
                </Tooltip>
              </Grid> */}
            </Grid>
          </Toolbar>
        </AppBar>
        {renderView()}
      </PaperBaseComponent>
    </BaseHomePage>
  );
}
