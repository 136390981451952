import React, { useState, useEffect, useCallback } from "react";
import {
  IconButton,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { validateUrlService } from "../../APIServices/UserAPIService";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export default function DealerUrlComponent(props) {
  const [loading, setLoading] = React.useState(null);
  const [set, setSet] = React.useState(Boolean());
  const [urlValid, setUrlValid] = React.useState(null);
  const [value, setValue] = useState(String());

  const validateUrl = useCallback(
    async (url) => {
      setLoading(true);

      try {
        let response = await validateUrlService(url);
        setUrlValid(response.code === 0);
        props.valid(response.code === 0);
      } catch (error) {}

      setLoading(Boolean());
    },
    [props]
  );

  // const handleTextChange = (e) => {
  //   setValue(e.target.value);
  //   props.onChange(e.target.value);
  // };

  const setStatus = (option) => {
    if (option) {
    } else {
    }
  };

  const renderEndAdornment = (status, loading) => {
    let view = null;
    if (loading) {
      view = <CircularProgress color="inherit" size={21} />;
    }
    if (urlValid !== null) {
      view = (
        <IconButton aria-label="" edge="end">
          {urlValid ? <CheckCircleIcon /> : <CancelIcon />}
        </IconButton>
      );
    }

    return view;
  };

  const showTextError = () => {
    let result = String();
    if (urlValid !== null) {
      result = urlValid ? String() : "DIRECCIÓN NO VALIDA";
    }
    return result;
  };

  useEffect(() => {
    if (props.value && !set) {
      setValue(props.value);
      setSet(true);
    }

    if (props.value.length > 3) {
      if (props.validate) {
        validateUrl(props.value);
      }
    }
    setStatus(urlValid);
  }, [urlValid, props.value, set, validateUrl, props.validate]);

  return (
    <TextField
      {...props}
      label={props.title}
      onChange={props.onChange}
      error={showTextError() !== String()}
      helperText={showTextError()}
      InputProps={{
        ...props.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            {"https://sweeven.live/"}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {renderEndAdornment(urlValid, loading)}
          </InputAdornment>
        ),
      }}
    />
  );
}
