import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, CircularProgress, Typography, Button, Box } from "@mui/material";
import { log } from "../../helpers/Logger";
import { StatusScreen } from "@mercadopago/sdk-react";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { PortableWifiOffSharp } from "@mui/icons-material";
initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);

export default function StatusPaymentComponent(props) {
  const { t } = useTranslation();
  const [ready, setReady] = useState(false);

  useEffect(() => {}, []);

  const onError = async (error) => {
    // callback llamado para todos los casos de error de Brick
  };

  const onReady = async (r) => {
    setReady(true);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">{t("")}</Typography>
      </Grid>
      {!ready && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      <Grid item xs={12}>
        <StatusScreen
          onReady={onReady}
          onError={onError}
          initialization={{
            paymentId: props.paymentIdentifier,
          }}
        />
      </Grid>
    </Grid>
  );
}
