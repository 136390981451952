export const AddressesType = {
  setAddresses: "[Addresses] setAddresses",
  handleSelectedAddress: "[Addresses] handleSelectedAddress",
  addressCreated: "[Addresses] addressCreated",
  addressDeleted: "[Addresses] addressDeleted",
  addressUpdated: "[Addresses] addressUpdated",
  editAddress: "[Addresses] editAddress",
  setLoading: "[Addresses] setLoading",
  setOpenDialog: "[Addresses] setOpenDialog",
  error: "[Addresses] error",
  clear: "[Addresses] clear",
};
