import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AutoShippingType } from "../types/AutoShippingType";
import {
  Paper,
  TextField,
  Box,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";
import ProductsList from "../products.json";

export const SelectProductsComponent = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.autoshipping.products);
  useImperativeHandle(ref, () => ({
    onClick() {
      document.getElementById("userForm").submitButton.click();
    },
  }));

  const getProducts = () => {
    let result = {};
    let productsActive = ProductsList.products.filter(
      (product) => product.active
    );
    result = productsActive.map((item) => {
      return {
        ...item,
        quantity: 0,
      };
    });
    if (props.products) {
      props.products.forEach((product) => {
        result.find((inProduct) => inProduct.sku === product.sku).quantity =
          product.quantity;
      });
    }
    return result;
  };
  const [productsList, setProductList] = useState(getProducts());

  const addItem = (product) =>
    dispatch({
      type: AutoShippingType.handleRemoveProduct,
      payload: product,
    });
  // {
  //   product.quantity += 1;
  //   let aux = productsList.filter((item) => item.sku !== product.sku);
  //   aux.push(product);
  //   aux.sort((a, b) => a.sku - b.sku);
  //   setProductList(aux);
  // };

  const removeItem = (product) => {
    if (product.quantity > 0) {
      product.quantity -= 1;
      let aux = productsList.filter((item) => item.sku !== product.sku);
      aux.push(product);
      aux.sort((a, b) => a.sku - b.sku);
      setProductList(aux);
    }
  };

  const getSelectedProducts = () => {
    let productsSelected = productsList.filter((item) => item.quantity > 0);
    if (productsSelected.length > 0) {
      props.onComplete("products", productsSelected);
    }
  };

  const handleTextChange = (e) => {
    //FIXME: WHAT??
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onComplete();
  };

  return (
    <Stack spacing={2}>
      <Box
        id="userForm"
        component="form"
        onSubmit={handleSubmit}
        sx={{ mt: 1 }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{"SKU"}</TableCell>
                <TableCell align="center">{t("name")}</TableCell>
                <TableCell align="center">{t("price")}</TableCell>
                <TableCell align="center">{t("calification")}</TableCell>
                <TableCell align="center">{t("quantity")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow
                  key={product.sku}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {product.sku}
                  </TableCell>
                  <TableCell align="center">{product.name}</TableCell>
                  <TableCell align="center">{`${Intl.NumberFormat(
                    "es-MX"
                  ).format(product.distributorPrice)} MXN`}</TableCell>
                  <TableCell align="center">
                    {product.calificationPoints}
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row">
                      <IconButton
                        onClick={() =>
                          dispatch({
                            type: AutoShippingType.handleRemoveProduct,
                            payload: product,
                          })
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                      <TextField
                        value={product.quantity}
                        inputProps={{ min: 0, style: { textAlign: "center" } }}
                        sx={{ width: "60px" }}
                        onChange={handleTextChange}
                      />
                      <IconButton
                        onClick={() =>
                          dispatch({
                            type: AutoShippingType.handleAddProduct,
                            payload: product,
                          })
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <input type="submit" style={{ display: "none" }} name="submitButton" />
      </Box>
    </Stack>
  );
});
