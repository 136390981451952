import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import BaseHomePage from "../General/BaseHomePage";
import { Grid, CircularProgress, Typography, Button, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import EditStepperComponent from "../../components/general/EditStepperComponent";
import AddressShippingComponent from "../../components/AddressShippingComponent";
import BillingShippingComponent from "../../components/BillingShippingComponent";
import { AutoShippingOrderTotalComponent } from "../../components/AutoShippingOrderTotalComponent";
import { SelectProductsComponent } from "../../components/SelectProductsComponent";
import { ShippingFrequencyComponent } from "../../components/ShippingFrequencyComponent";
import PaperBaseComponent from "../../components/general/PaperBaseComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { updateAutoshippingService } from "../../APIServices/AutoShippingAPIService";

export default function UpdateAutoShippingPage(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const order = location.state ? location.state.data : {};
  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });
  const [currentIndex, setCurrentIndex] = useState(Number());
  // const navigate = useNavigate();
  const [data, setData] = useState({
    email: order.email,
    phone: order.phone,
    products: {},
    interval: {},
    shippingAddress: {},
    billingAddress: {},
  });

  const createAutoShippingService = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = await updateAutoshippingService(order.identifier, data);

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
      success: response.code,
    });
  }, [data, order.identifier]);

  useEffect(() => {
    if (currentIndex === 5 && viewData.needData) {
      createAutoShippingService();
    }
  }, [createAutoShippingService, currentIndex, viewData]);

  const onComplete = (identifier, newData) => {
    let aux = {
      ...data,
      [identifier]: newData,
    };
    setCurrentIndex(currentIndex + 1);
    setData(aux);
  };

  const renderResultView = () => {
    let text =
      viewData.success === 0
        ? "Tu autoenvio se ha actualizado correctamente. En breve se mandará un enlace la confirmación de pago"
        : "Ha ocurrido un error, por favor contacte al administrador ";

    let icon =
      viewData.success === 0 ? (
        <CheckCircleIcon sx={{ fontSize: 60 }} />
      ) : (
        <ErrorIcon sx={{ fontSize: 60 }} />
      );

    return viewData.loading ? (
      <CircularProgress sx={{ m: 3 }} />
    ) : (
      <Grid
        container
        spacing={3}
        sx={{
          m: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid item xs={12}>
          {icon}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{text}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button href="https://sweeven.life" variant="contained">
            {t("back")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderView = () => {
    switch (currentIndex) {
      case 0:
        return (
          <SelectProductsComponent
            products={order.products}
            onComplete={onComplete}
          />
        );
      case 1:
        return (
          <AutoShippingOrderTotalComponent
            products={data.products}
            onComplete={onComplete}
          />
        );
      case 2:
        return (
          <ShippingFrequencyComponent
            interval={order.interval}
            onComplete={onComplete}
          />
        );
      case 3:
        return (
          <AddressShippingComponent
            address={order.shippingAddress}
            onComplete={onComplete}
          />
        );
      case 4:
        return (
          <BillingShippingComponent
            address={order.billingAddress}
            fillWith={data.shippingAddress}
            onComplete={onComplete}
          />
        );
      case 5:
        return renderResultView();

      default:
        return <SelectProductsComponent onComplete={onComplete} />;
    }
  };

  const renderLoggedView = () => {
    return (
      <Box>
        {currentIndex < 5 && (
          <EditStepperComponent index={currentIndex}></EditStepperComponent>
        )}
        {renderView()}
      </Box>
    );
  };

  return (
    <BaseHomePage>
      <PaperBaseComponent sx={{ m: 2 }}>
        {renderLoggedView()}
      </PaperBaseComponent>
    </BaseHomePage>
  );
}
