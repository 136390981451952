import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = [
  "Seleccionar productos",
  "Revisón de pedido",
  "Frecuencia de envios",
  "Dirección de envio",
  "Dirección de facturación",
];

export default function EditStepperComponent(props) {
  return (
    <Box sx={{ m: 3, width: "100%" }}>
      <Stepper activeStep={props.index} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
