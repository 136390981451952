import React from "react";
import { Routes, Route } from "react-router-dom";
import CreateAutoshippingPage from "../pages/Autoshipping/CreateAutoshippingPage";
import AutoShippingIndexPage from "../pages/Autoshipping/AutoShippingIndexPage";
import AutoShippingDetailPage from "../pages/Autoshipping/AutoShippingDetailPage";
import UpdateAutoShippingPage from "../pages/Autoshipping/UpdateAutoShippingPage";

const AutoShippingRouter = (props) => {
  return (
    <Routes>
      <Route path="/" element={<AutoShippingIndexPage />} />
      <Route
        path="detail"
        element={<AutoShippingDetailPage user={props.user} />}
      />
      <Route path="create" element={<CreateAutoshippingPage />} />
      <Route path="update" element={<UpdateAutoShippingPage />} />
    </Routes>
  );
};

export default AutoShippingRouter;
