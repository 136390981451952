import { SignUpType } from "../types/SignUpType";
import ProductsList from "../products.json";
import { log } from "../helpers/Logger";
import { FrequencyType } from "../models/enums/FrequencyType";

let INITIAL_STATE = {
  firstName: {
    value: String(),
    error: String(),
  },
  lastName: {
    value: String(),
    error: String(),
  },
  email: {
    value: String(),
    error: String(),
  },
  confirmEmail: {
    value: String(),
    error: String(),
  },
  password: {
    value: String(),
    error: String(),
  },
  confirmPassword: {
    value: String(),
    error: String(),
  },
  dealerURL: {
    value: String(),
    error: String(),
  },
  phone: {
    value: {
      code: String(),
      number: String(),
    },
    error: String(),
  },
  rfc: {
    value: String(),
    error: String(),
  },
  starterKit: {
    identifier: String(),
    price: Number(),
  },
};

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  INITIAL_STATE = {
    firstName: {
      value: "JoseX",
      error: String(),
    },
    lastName: {
      value: "RodriguezX",
      error: String(),
    },
    email: {
      value: "joserodriguez5@gmail.com",
      error: String(),
    },
    confirmEmail: {
      value: "joserodriguez5@gmail.com",
      error: String(),
    },
    password: {
      value: "Sweeven1234",
      error: String(),
    },
    confirmPassword: {
      value: "Sweeven1234",
      error: String(),
    },
    dealerURL: {
      value: "josero5",
      error: String(),
    },
    phone: {
      value: {
        code: "+52",
        number: "5558698881",
      },
      error: String(),
    },
    rfc: {
      value: "PORR980511GR5",
      error: String(),
    },
    starterKit: {
      identifier: String(),
      price: Number(),
    },
  };
}

export const SignUpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SignUpType.handleTextChange:
      return {
        ...state,
        [action.payload.name]: {
          value: action.payload.value,
          error: String(),
        },
      };

    case SignUpType.handleAddressesChange:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case SignUpType.handleSelectKit:
      return {
        ...state,
        starterKit: action.payload,
      };

    case SignUpType.error:
      return {
        ...state,
        ...action.payload,
      };

    case SignUpType.clear:
      return INITIAL_STATE;

    default:
      return state;
  }
};
