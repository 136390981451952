import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import UISelect from "../general/UISelect";
import UIButton from "../general/UIButton";

const products = [
  { url: "Travel", name: "Travel", type: 0 },
  { url: "Gummies", name: "Gummies", type: 0 },
];

export default function AddPromotionalLinksComponent() {
  const { t } = useTranslation();
  const [data, setData] = useState({
    typeSelected: String(),
  });

  const mappingProducts = () => {
    return products.map((product) => {
      return {
        value: product.type,
        title: product.name,
      };
    });
  };

  const handleChangeData = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const renderView = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <UISelect
            fullwidth
            title={t("selectLink")}
            labelId={""}
            name={"name"}
            id={"selectedType"}
            label={t("selectLink")}
            onChange={handleChangeData}
            options={mappingProducts()}
            value={data.typeSelected}
          ></UISelect>
        </Grid>
        <Grid item xs={12} md={6}>
          <UIButton
            fullwidth
            variant="contained"
            title={t("Generar link")}
          ></UIButton>
        </Grid>
      </Grid>
    );
  };

  return renderView();
}
