import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import AutoshippingRouter from "./AutoshippingRouter";
import SignInPage from "../pages/Authentication/SignInPage";
import ScrollToTop from "../helpers/ScrollToTop";
import PromotionalLinksRouter from "./PromotionalLinksRouter";
import DealerRouter from "./DealerRouter";
import CommissionsRouter from "./CommissionsRouter";
import GenealogyRouter from "./GenealogyRouter";
import HomePage from "../pages/HomePage";
import ResultPage from "../pages/General/ResultPage";
import PaymentPage from "../pages/General/PaymentPage";
import RegisterPage from "../pages/Authentication/RegisterPage";
import RecoverPage from "../pages/Authentication/RecoverPage";
import ResetPage from "../pages/Authentication/ResetPage";

export const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/signin"
          element={
            <PublicRouter>
              <SignInPage />
            </PublicRouter>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRouter>
              <RegisterPage />
            </PublicRouter>
          }
        />
        <Route
          path="/recover"
          element={
            <PublicRouter>
              <RecoverPage />
            </PublicRouter>
          }
        />
        <Route
          path="/reset"
          element={
            <PublicRouter>
              <ResetPage />
            </PublicRouter>
          }
        />
        <Route
          path="/dealer/signup"
          element={
            <PrivateRouter>
              <RegisterPage user={true} />
            </PrivateRouter>
          }
        />
        <Route
          path="/home"
          element={
            <PrivateRouter>
              <HomePage />
            </PrivateRouter>
          }
        />
        <Route
          path="/autoshipping/*"
          element={
            <PrivateRouter>
              <AutoshippingRouter />
            </PrivateRouter>
          }
        />

        <Route
          path="/commission/*"
          element={
            <PrivateRouter>
              <PromotionalLinksRouter />
            </PrivateRouter>
          }
        />

        <Route
          path="/dealer/*"
          element={
            <PrivateRouter>
              <DealerRouter />
            </PrivateRouter>
          }
        />

        <Route
          path="/commissions/*"
          element={
            <PrivateRouter>
              <CommissionsRouter />
            </PrivateRouter>
          }
        />

        <Route
          path="/promotionallinks/*"
          element={
            <PrivateRouter>
              <PromotionalLinksRouter />
            </PrivateRouter>
          }
        />
        <Route
          path="/genealogy/*"
          element={
            <PrivateRouter>
              <GenealogyRouter />
            </PrivateRouter>
          }
        />

        <Route
          path="/external/result"
          element={
            <PrivateRouter>
              <ResultPage />
            </PrivateRouter>
          }
        />

        <Route path="/payment" element={<PaymentPage />} />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </Router>
  );
};
