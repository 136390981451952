import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import BaseHomePage from "../General/BaseHomePage";
import { Grid, CircularProgress, Typography, Button, Box } from "@mui/material";
import { AddressesListComponent } from "../../components/general/AddressesListComponent";

import CaptureContactComponent from "../../components/CaptureContactComponent";
import { AutoShippingOrderTotalComponent } from "../../components/AutoShippingOrderTotalComponent";
import { SelectProductsComponent } from "../../components/SelectProductsComponent";
import { ShippingFrequencyComponent } from "../../components/ShippingFrequencyComponent";
import PaperBaseComponent from "../../components/general/PaperBaseComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import UIStepper from "../../components/general/UIStepper";
import UIButton from "../../components/general/UIButton";
import { AutoShippingType } from "../../types/AutoShippingType";
import CardPaymentComponent from "../../components/payment/CardPaymentComponent";
import { createAutoShippingAction } from "../../actions/AutoShippingActions";

export default function CreateAutoshippingPage(props) {
  const { t } = useTranslation();
  const ref = useRef();
  const dispatch = useDispatch();
  const userIdentifier = useSelector((state) => state.auth.id);
  const data = useSelector((state) => state.autoshipping);
  const user = useSelector((state) => state.user);
  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });

  const steps = useMemo(
    () => [
      t("selectProducts"),
      t("checkOrder"),
      t("shippingFrequency"),
      t("shippingAddress"),
      t("billingAddress"),
      t("payment"),
      t("resume"),
    ],
    [t]
  );

  const [currentIndex, setCurrentIndex] = useState(Number());
  // const navigate = useNavigate();
  // const [data, setData] = useState({
  //   products: {},
  //   interval: {},
  //   shippingAddress: {},
  //   billingAddress: {},
  // });

  const createAutoShippingService = useCallback(
    async (formData) => {
      let newData = {
        billingAddress: data.billingAddress,
        interval: data.interval,
        selectedProducts: data.selectedProducts,
        shippingAddress: data.shippingAddress,
      };

      // let response = await createAutoshippingService(userIdentifier, {
      //   ...formData,
      //   ...newData,
      // });
      dispatch(
        createAutoShippingAction({
          userIdentifier,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          ...formData,
          ...newData,
          mercadoPagoIdentifier: user.mercadoPagoIdentifier,
        })
      );
    },
    [data, user.dealerIdentifier, user.email, userIdentifier]
  );

  const handleSelectedProducts = useCallback(async (option) => {
    setViewData((prev) => {
      return {
        ...prev,
        continue: option,
      };
    });
  }, []);

  useEffect(() => {
    if (steps[currentIndex] === t("selectProducts")) {
      if (Object.keys(data.selectedProducts).length > 0) {
        handleSelectedProducts(true);
      } else {
        handleSelectedProducts(false);
      }
    }
    if (steps[currentIndex] === t("shippingFrequency")) {
      if (Object.keys(data.interval).length > 0) {
        handleSelectedProducts(true);
      } else {
        handleSelectedProducts(false);
      }
    }

    if (steps[currentIndex] === t("shippingAddress")) {
      if (Object.keys(data.shippingAddress).length > 0) {
        handleSelectedProducts(true);
      } else {
        handleSelectedProducts(false);
      }
    }

    if (steps[currentIndex] === t("billingAddress")) {
      if (Object.keys(data.billingAddress).length > 0) {
        handleSelectedProducts(true);
      } else {
        handleSelectedProducts(false);
      }
    }

    if (steps[currentIndex] === t("billingAddress")) {
      if (Object.keys(data.billingAddress).length > 0) {
        handleSelectedProducts(true);
      } else {
        handleSelectedProducts(false);
      }
    }

    if (steps[currentIndex] === t("payment")) {
      handleSelectedProducts(false);
    }

    // if (currentIndex === 5 && viewData.needData) {
    //   createAutoShippingService();
    // }
  }, [handleSelectedProducts, data, currentIndex, steps, t]);

  const onComplete = (identifier, newData) => {
    let aux = {
      ...data,
      [identifier]: newData,
    };
    setCurrentIndex(currentIndex + 1);
    dispatch({ type: AutoShippingType.setData, payload: aux });
  };

  const onCompletePayment = (data) => {
    createAutoShippingService(data);
  };

  const renderResultView = () => {
    let text =
      viewData.success === 0
        ? "Tu autoenvio se ha creado correctamente. En breve se mandará un enlace la confirmación de pago"
        : "Ha ocurrido un error, por favor contacte al administrador ";

    let icon =
      viewData.success === 0 ? (
        <CheckCircleIcon sx={{ fontSize: 60 }} />
      ) : (
        <ErrorIcon sx={{ fontSize: 60 }} />
      );

    return viewData.loading ? (
      <CircularProgress sx={{ m: 3 }} />
    ) : (
      <Grid
        container
        spacing={3}
        sx={{
          m: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid item xs={12}>
          {icon}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{text}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button href="/" variant="contained">
            {t("back")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderView = () => {
    switch (steps[currentIndex]) {
      case t("selectProducts"):
        return <SelectProductsComponent ref={ref} onComplete={onComplete} />;
      case t("checkOrder"):
        return (
          <AutoShippingOrderTotalComponent
            ref={ref}
            products={data.selectedProducts}
            onComplete={onComplete}
          />
        );
      case t("shippingFrequency"):
        return <ShippingFrequencyComponent ref={ref} onComplete={onComplete} />;
      case t("shippingAddress"):
        return (
          <AddressesListComponent
            ref={ref}
            viewIdentifier="shippingAddress"
            title={t("shippingAddress")}
            subtitle={t("selectAddress")}
            selectedItem={data.shippingAddress.identifier}
            onComplete={onComplete}
            dispatch={AutoShippingType.handleSelectedAddress}
          />
        );
      case t("billingAddress"):
        return (
          <AddressesListComponent
            ref={ref}
            viewIdentifier="billingAddress"
            title={t("billingAddress")}
            subtitle={t("selectAddress")}
            selectedItem={data.billingAddress.identifier}
            onComplete={onComplete}
            dispatch={AutoShippingType.handleSelectedAddress}
          />
        );
      case t("payment"):
        let Total = Number();
        if (data.selectedProducts) {
          Total = data.selectedProducts.reduce(
            (total, product) =>
              total + product.distributorPrice * product.quantity,
            0
          );
        }

        if (data.error) {
          return <Typography>error</Typography>;
        } else {
          return (
            <CardPaymentComponent
              total={Total}
              onComplete={onCompletePayment}
            />
          );
        }

      case t("resume"):
        return renderResultView();
      default:
        return <CaptureContactComponent onComplete={onComplete} />;
    }
  };

  const renderButton = () => {
    let text = t("next");
    let button = null;
    button = (
      <UIButton
        fullWidth
        disabled={!viewData.continue}
        type="submit"
        variant="contained"
        loading={viewData.loading}
        title={text}
        onClick={() => {
          ref.current.onClick();
        }}
      />
    );

    return (
      <Grid container spacing={1}>
        {currentIndex > 0 && (
          <Grid item xs={6}>
            <UIButton
              fullWidth
              variant="outlined"
              title={t("back")}
              onClick={() => {
                setCurrentIndex(currentIndex - 1);
              }}
            />
          </Grid>
        )}
        <Grid item xs={currentIndex > 0 ? 6 : 12}>
          {button}
        </Grid>
      </Grid>
    );
  };

  const renderLoggedView = () => {
    return (
      <Box>
        <UIStepper index={currentIndex} steps={steps} />
        {renderView()}
        {renderButton()}
      </Box>
    );
  };

  return (
    <BaseHomePage>
      <PaperBaseComponent sx={{ m: 2 }}>
        {renderLoggedView()}
      </PaperBaseComponent>
    </BaseHomePage>
  );
}
