/**
 * AutoShippingActions.jsx
 */
import { log } from "../helpers/Logger";
import axios from "axios";
import { store } from "../store/store";
import EndPoints from "../config/EndPoints.json";
import { AutoShippingType } from "../types/AutoShippingType";

export const createAutoShippingAction = (data) => {
  const state = store.getState();
  const token = state.auth.token;
  return async (dispatch) => {
    try {
      const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.autoshippingEndpoint}`;
      const response = await axios.post(endpoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.code === Number()) {
        dispatch({
          type: AutoShippingType.autoshippingCreated,
        });
      } else {
        dispatch({
          type: AutoShippingType.error,
          payload: { title: "Error", message: "Error" },
        });
      }
    } catch (error) {
      log(error);
      //TODO: HANDLE ERROR
      dispatch({
        type: AutoShippingType.error,
        payload: { title: "Error", message: "Error" },
      });
    }
  };
};
