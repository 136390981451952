import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { SignUpType } from "../types/SignUpType";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Box, InputAdornment } from "@mui/material";
import DealerUrlComponent from "./Enroll/DealerUrlComponent";
import { crypt } from "../services/Crypter";
//var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
const regex = new RegExp("^(?=.{8,})");
// const rfcRegex = /^[A-Za-z]{4}\d{6}(?:[A-Za-z\d]{3})?$/;
const regexPersonaFisica = /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/;
const regexPersonaMoral = /^[A-Z]{3}\d{6}[A-Z0-9]{3}$/;

export const UserFormComponent = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.signup);
  const [disabled, setDisabled] = useState(Boolean());
  const [emailDisabled, setEmailDisabled] = useState(Boolean());

  // const [data, setData] = useState({
  //   firstName: String(),
  //   lastName: String(),
  //   email: String(),
  //   confirmEmail: String(),
  //   password: String(),
  //   confirmPassword: String(),
  //   dealerURL: String(),
  //   phone: String(),
  //   rfc: String(),
  // });

  const [urlValid, setUrlValid] = useState(Boolean());
  // const INITIAL_FIELDS = {
  //   firstName: { value: "", error: String() },

  //   lastName: {
  //     value: "",
  //     error: String(),
  //   },

  //   email: {
  //     value: "",
  //     error: String(),
  //   },

  //   confirmEmail: {
  //     value: "",
  //     error: String(),
  //   },

  //   password: {
  //     value: "",
  //     error: String(),
  //   },

  //   confirmPassword: {
  //     value: "",
  //     error: String(),
  //   },

  //   dealerURL: {
  //     value: "",
  //     error: String(),
  //   },
  //   phone: {
  //     value: "",
  //     error: String(),
  //   },

  //   rfc: {
  //     value: "",
  //     error: String(),
  //   },
  // };
  // const [fields, setField] = useState(INITIAL_FIELDS);

  useImperativeHandle(ref, () => ({
    onClick() {
      document.getElementById("userForm").submitButton.click();
    },
  }));

  useEffect(() => {
    if (props.disabled) {
      setDisabled(props.disabled);
    }

    if (props.emailDisabled) {
      setEmailDisabled(props.emailDisabled);
    }

    // if (props.user) {
    //   setData({
    //     firstName: props.user.firstName,
    //     lastName: props.user.lastName,
    //     email: props.user.email,
    //     confirmEmail: props.user.confirmEmail,
    //     dealerURL: props.user.dealerURL,
    //     phone: props.user.phone,
    //     rfc: props.user.rfc,
    //     password: "********",
    //     confirmPassword: "********",
    //   });
    // } else if (props.data) {
    //   if (props.type === Number()) {
    //     setData((prev) => {
    //       return {
    //         ...prev,
    //         email: props.data.email,
    //         confirmEmail: props.data.confirmEmail,
    //       };
    //     });
    //     setEmailDisabled(true);
    //   } else {
    //     setData((prev) => {
    //       return {
    //         ...prev,
    //         email: props.data.email,
    //         confirmEmail: props.data.confirmEmail,
    //         password: "********",
    //         confirmPassword: "********",
    //       };
    //     });
    //     setExternalDisabled(true);
    //   }
    // } else if (process.env.REACT_APP_ENVIRONMENT === "development") {
    //   setData({
    //     firstName: "SWEEVEN USER",
    //     lastName: "LASTNAME",
    //     email: "it_14@sweeven.life",
    //     confirmEmail: "it_14@sweeven.life",
    //     password: "Abcd1234",
    //     confirmPassword: "Abcd1234",
    //     dealerURL: "URL93",
    //     phone: "5515555518",
    //     rfc: "12312312DFG12",
    //   });
    // }
  }, [props.user, props.emailDisabled, props.disabled]);

  const handleTextChange = (e) => {
    let value = e.target.value;
    // if (e.target.name === "password" || e.target.name === "confirmPassword") {
    //   value = crypt(value);
    // }

    // setData({
    //   ...data,
    //   [e.target.name]: value,
    // });

    if (e.target.name === "phone") {
      value = {
        code: "+52",
        number: value,
      };
    }

    if (e.target.name === "rfc") {
      value = value.toUpperCase();
    }

    dispatch({
      type: SignUpType.handleTextChange,
      payload: {
        name: e.target.name,
        value: value,
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (data.email.value !== data.confirmEmail.value) {
      dispatch({
        type: SignUpType.error,
        payload: {
          email: {
            ...data.email,
            error: t("mustBeSameEmail"),
          },
          confirmEmail: {
            ...data.confirmEmail,
            error: t("mustBeSameEmail"),
          },
        },
      });
      return;
    }

    if (data.password.value === data.confirmPassword.value) {
      if (!regex.test(data.password)) {
        dispatch({
          type: SignUpType.error,
          payload: {
            password: {
              ...data.password,
              error: t("minimumcharacters"),
            },
          },
        });
        // setField((prev) => {
        //   return {
        //     ...prev,
        //     password: {
        //       ...prev.password,
        //       error: t("minimumcharacters"),
        //     },
        //   };
        // });
        return;
      }
    } else {
      dispatch({
        type: SignUpType.error,
        payload: {
          password: {
            ...data.password,
            error: t("mustBeSameEmail"),
          },
          confirmPassword: {
            ...data.confirmPassword,
            error: t("mustBeSamePassword"),
          },
        },
      });
      return;
    }

    if (!urlValid) {
      dispatch({
        type: SignUpType.error,
        payload: {
          dealerURL: {
            ...data.dealerURL,
            error: "URL NO VALIDA",
          },
        },
      });
      return;
    }

    if (data.phone.value.number.length < 8) {
      dispatch({
        type: SignUpType.error,
        payload: {
          dealerURL: {
            ...data.dealerURL,
            error: "URL NO VALIDA",
          },
        },
      });
      // setField((prev) => {
      //   return {
      //     ...prev,
      //     phone: {
      //       ...prev.phone,
      //       error: t("notValid"),
      //     },
      //   };
      // });
      return;
    }

    if (
      !regexPersonaFisica.test(data.rfc.value) &&
      !regexPersonaMoral.test(data.rfc.value)
    ) {
      dispatch({
        type: SignUpType.error,
        payload: {
          rfc: {
            ...data.rfc,
            error: t("notValid"),
          },
        },
      });
      return;
    }

    // let newData = {
    //   ...data,
    //   phone: {
    //     code: `+52`,
    //     number: data.phone,
    //   },
    // };
    props.onComplete("Datos generales");
  };

  return (
    <Box
      sx={{
        mt: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        id="userForm"
        component="form"
        onSubmit={handleSubmit}
        sx={{ mt: 1 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              value={data.firstName.value}
              margin="normal"
              required
              disabled={disabled}
              fullWidth
              name="firstName"
              label={t("firstName")}
              type="firstName"
              id="firstName"
              autoComplete="firstName"
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          {/*  */}
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={disabled}
              value={data.lastName.value}
              margin="normal"
              required
              fullWidth
              name="lastName"
              label={t("lastName")}
              type="lastName"
              id="lastName"
              autoComplete="lastName"
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          {/*  */}
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={disabled || emailDisabled}
              value={data.email.value}
              error={data.email.error !== String()}
              helperText={data.email.error}
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              autoComplete="email"
              label={t("email")}
              name="email"
            />
          </Grid>
          {/*  */}
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={disabled || emailDisabled}
              value={data.confirmEmail.value}
              error={data.confirmEmail.error !== String()}
              helperText={data.confirmEmail.error}
              margin="normal"
              required
              fullWidth
              id="confirmEmail"
              label={t("confirmEmail")}
              name="confirmEmail"
              autoComplete="confirmEmail"
              type="email"
            />
          </Grid>

          {/*  */}
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={disabled}
              value={data.password.value}
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("password")}
              type="password"
              id="password"
              error={data.password.error !== String()}
              helperText={data.password.error}
              autoComplete="password"
            />
          </Grid>
          {/*  */}
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={disabled}
              value={data.confirmPassword.value}
              error={data.confirmPassword.error !== String()}
              helperText={data.confirmPassword.error}
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label={t("confirmPassword")}
              type="password"
              id="confirmPassword"
              autoComplete="password"
            />
          </Grid>
          {/*  */}
          <Grid item xs={12}>
            <DealerUrlComponent
              onChange={handleTextChange}
              disabled={disabled}
              value={data.dealerURL.value}
              margin="normal"
              required
              fullWidth
              validate={!disabled}
              name="dealerURL"
              label={t("dealerURL")}
              type="text"
              id="dealerURL"
              error={data.dealerURL.error !== String()}
              helperText={data.dealerURL.error}
              valid={(valid) => {
                setUrlValid(valid);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(event) => {
                if (Number(event.target.value)) {
                  handleTextChange(event);
                }
              }}
              disabled={disabled}
              value={data.phone.value.number}
              error={data.phone.error !== String()}
              helperText={data.phone.error}
              margin="normal"
              required
              fullWidth
              name="phone"
              label={t("phone")}
              type="text"
              id="phone"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{"+52"}</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              disabled={disabled}
              value={data.rfc.value}
              error={data.rfc.error !== String()}
              helperText={data.rfc.error}
              margin="normal"
              required
              fullWidth
              name="rfc"
              label={t("rfc")}
              type="text"
              id="rfc"
            />
          </Grid>
        </Grid>

        <input type="submit" style={{ display: "none" }} name="submitButton" />
      </Box>
    </Box>
  );
});
