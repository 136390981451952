import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, onAuthStateChanged } from "../firebase/firebaseConfig";
import PageLoadingComponent from "../components/general/PageLoadingComponent";
import { getUserService } from "../APIServices/UserAPIService";
import { login, signOutAction } from "../actions/AuthenticationAction";
import { updateInfo } from "../actions/UserActions";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { log } from "../helpers/Logger";

const PrivateRouter = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logged, setLogged] = useState(null);
  const [user, setUser] = useState(null);
  const register = useSelector((state) => state.auth.registered);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        log(user);
        let response = await getUserService(user.uid);
        if (response.code === 0) {
          if (response.data.status === "enabled") {
            dispatch(updateInfo(response.data));
          } else {
            //TODO: SEND ERROR
            dispatch(signOutAction());
          }
        }

        setLogged(true);
        setUser(user);
        dispatch(login(user.uid, user.displayName, user.accessToken));
      } else {
        setUser(null);
        setLogged(Boolean());
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (register) {
      navigate("/");
    }
  }, [register, navigate]);

  if (logged !== null || logged) {
    if (user === null) {
      if (location.pathname === "/external/result") {
        return children;
      } else {
        return <Navigate to="/signin" replace />;
      }
    }
    return children;
  } else {
    return <PageLoadingComponent />;
  }
};

export default PrivateRouter;
