import { CommissionsType } from "../types/CommissionsType";

let INITIAL_STATE = {
  commissions: null,
};

export const CommissionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CommissionsType.setCommissions:
      return {
        ...state,
        commissions: action.payload,
      };

    case CommissionsType.error:
      return {
        ...state,
        commissions: [],
      };

    default:
      return state;
  }
};
