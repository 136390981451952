import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import BaseHomePage from "../General/BaseHomePage";
import {
  Grid,
  Button,
  AppBar,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PaperBaseComponent from "../../components/general/PaperBaseComponent";
import { getCommissionsActions } from "../../actions/CommissionsActions";
import UISelect from "../../components/general/UISelect";
import { log } from "../../helpers/Logger";
import dayjs from "dayjs";
import weekdayPlugin from "dayjs/plugin/weekday";
dayjs.extend(weekdayPlugin);

const findMonday = () => {
  let aux = dayjs();
  while (aux.day() !== Number()) {
    aux = aux.subtract(1, "days");
  }
  aux = aux = aux.subtract(6, "days");
  return aux;
};

const phrases = [
  "Cada punto que generas te acerca más a tus metas. ¡Sigue adelante!",
  "El esfuerzo constante cosecha resultados duraderos. ¡No pares!",
  "Cada nuevo punto es un paso hacia el éxito en tu negocio. ¡Persiste!",
  "Tu dedicación en tu negocio hoy construye el futuro que deseas mañana. ¡Ve por más!",
  "Los pequeños avances diarios en puntos te llevarán a grandes logros. ¡Tú puedes!",
];

export default function CommissionsListPage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState(Number());
  const user = useSelector((state) => state.user);
  const commissions = useSelector((state) => state.commissions);
  const [selectDate, setSelectDate] = useState(findMonday());

  const getCommissionsRemote = useCallback(async () => {
    let date = `${selectDate
      .add(6, "days")
      .format("YYYY-MM-DD")}-${selectDate.date()}`;
    dispatch(getCommissionsActions(type, date, user.dealerIdentifier));
  }, [selectDate, dispatch, type, user.dealerIdentifier]);

  const getDistributorPrice = (data) => {
    return data.reduce((total, item) => total + item.distributorPrice, 0);
  };

  useEffect(() => {
    if (commissions.commissions === null) {
      getCommissionsRemote();
    }
  }, [getCommissionsRemote, commissions]);

  const renderTable = () => {
    let view = null;
    if (commissions.commissions) {
      if (commissions.commissions.length > 0) {
        view = (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t("address")}</TableCell>
                  <TableCell align="right">{t("price")}</TableCell>
                  <TableCell align="right">{t("day")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {commissions.commissions.map((item) => (
                  <TableRow
                    key={item.identifier}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      navigate("detail", { state: { data: item } });
                    }}
                  >
                    <TableCell align="left" component="th" scope="row">
                      {item.shippingAddress.address1}
                    </TableCell>
                    <TableCell align="right">
                      {`${getDistributorPrice(item.products)} MXN`}
                    </TableCell>
                    <TableCell align="right">
                      {item.interval.startedDate.split("-")[2]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      } else {
        view = (
          <Typography variant="h5" m={3} sx={{ textAlign: "center" }}>
            {phrases[Math.floor(Math.random() * phrases.length)]}
          </Typography>
        );
      }
    } else {
      view = <CircularProgress />;
    }
    return view;
  };

  const renderView = () => {
    return <React.Fragment>{renderTable()}</React.Fragment>;
  };

  const renderToolBar = () => {};

  return (
    <BaseHomePage>
      <PaperBaseComponent>
        <AppBar
          position="static"
          color="default"
          align="center"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              {/* <Grid item>
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </Grid> */}
              {/* <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by email address, phone number, or user UID"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: "default" },
                  }}
                  variant="standard"
                />
              </Grid> */}
              <Grid item xs />
              <Grid item>
                <UISelect
                  title={t("commission")}
                  label={t("commission")}
                  value={type}
                  options={[
                    { name: t("weekly"), value: 0 },
                    { name: t("monthly"), value: 1 },
                  ]}
                  onChange={(event) => {
                    setType(event.target.value);
                  }}
                />
              </Grid>

              {/* <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t("startDate")}
                    inputFormat="DD-MM-YYYY"
                    value={new Date()}
                    shouldDisableDate={(date) => date.day() !== 1}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </LocalizationProvider>
              </Grid> */}

              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t("fromDate")}
                    inputFormat={type === 0 ? "DD-MM-YYYY" : "MM-YYYY"}
                    value={selectDate}
                    openTo={type === 0 ? "day" : "month"}
                    shouldDisableDate={(date) => date.day() !== 1}
                    views={
                      type === Number()
                        ? ["year", "month", "day"]
                        : ["year", "month"]
                    }
                    renderInput={(props) => <TextField {...props} />}
                    maxDate={findMonday()}
                    onChange={(date) => {
                      setSelectDate(date);
                    }}
                    onClose={(event) => {}}
                    onOpen={(event) => {}}
                    onAccept={(value) => {
                      //TODO: ADD FUNCIONALITY OR REMOVE
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              {type === 0 && (
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled
                      inputFormat="DD-MM-YYYY"
                      value={selectDate.add(6, "days")}
                      label={t("toDate")}
                      renderInput={(props) => <TextField {...props} />}
                      onChange={(event) => {}}
                    />
                  </LocalizationProvider>
                </Grid>
              )}

              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    getCommissionsRemote();
                  }}
                  sx={{ mr: 1 }}
                >
                  {t("consult")}
                </Button>
              </Grid>
              <Grid item xs />
              {/* <Grid item>
                <Tooltip title="Reload">
                  <IconButton>
                    <RefreshIcon color="inherit" sx={{ display: "block" }} />
                  </IconButton>
                </Tooltip>
              </Grid> */}
            </Grid>
          </Toolbar>
        </AppBar>
        {renderView()}
      </PaperBaseComponent>
    </BaseHomePage>
  );
}
