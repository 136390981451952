import React from "react";
import { Routes, Route } from "react-router-dom";
import CommissionsListPage from "../pages/Commissions/CommissionsListPage";

const CommissionsRouter = (props) => {
  return (
    <Routes>
      <Route path="/" element={<CommissionsListPage />} />
    </Routes>
  );
};

export default CommissionsRouter;
