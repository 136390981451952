import * as React from "react";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";

export default function PaperBaseComponent(props) {
  return (
    <Paper
      sx={{
        bgcolor: "#FFF",
        overflow: "hidden",
        maxWidth: "md",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          m: 3,
        }}
      >
        {props.children}
      </Box>
    </Paper>
  );
}
