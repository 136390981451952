/**
 * CommissionsActions.jsx
 */
import { log } from "../helpers/Logger";
import axios from "axios";
import { store } from "../store/store";
import EndPoints from "../config/EndPoints.json";
import { CommissionsType } from "../types/CommissionsType";

export const getCommissionsActions = (type, date, dealerIdentifier) => {
  const state = store.getState();
  const token = state.auth.token;
  return async (dispatch) => {
    try {
      const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.getCommissionsEndpoint}`;
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: {
          type,
          date,
          dealerIdentifier,
        },
      });
      dispatch({
        type: CommissionsType.setCommissions,
        payload: response.data.data,
      });
    } catch (error) {
      log(error);
      //TODO: HANDLE ERROR
      dispatch({
        type: CommissionsType.error,
        payload: {
          error: {
            titles: "Error",
            message: error,
          },
        },
      });
    }
  };
};
