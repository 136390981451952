import React, { useEffect, useState } from "react";
import SweevenLogo from "../../resources/images/logo_sweeven_light.svg";
import BaseHomePage from "./BaseHomePage";
import PaperBaseComponent from "../../components/general/PaperBaseComponent";
import { Box, Container, CssBaseline, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";

import { useSearchParams } from "react-router-dom";
import { log } from "../../helpers/Logger";

export default function RegisterPage(props) {
  const user = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();
  const [currentStatus, setCurrentStatus] = useState(String());

  useEffect(() => {
    setCurrentStatus(searchParams.get("status"));

    // if (currentStatus === "success") {
    //   if (interval === null) {
    //     setInterval(
    //       setInterval(() => {
    //         setRemainingTime(remainingTime - 1);
    //       }, 1000)
    //     );

    //     if (remainingTime === Number()) {
    //       clearInterval(interval);
    //     }
    //   }
    // }
  }, [searchParams]);
  const renderView = () => {
    let result = null;
    switch (currentStatus) {
      case "success":
        result = (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon sx={{ fontSize: "9vw", color: "green" }} />
            <Typography variant="h5">{"Pago exitoso"}</Typography>
            {/* <Typography variant="h5">{`Serás redireccionado en ${remainingTime}`}</Typography> */}
          </Box>
        );
        break;
      case "pending":
        result = (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ErrorIcon sx={{ fontSize: "9vw", color: "#ebcf34" }} />
            <Typography variant="h5">{"Pago pendiente"}</Typography>
          </Box>
        );
        break;
      case "failure":
        result = (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CancelIcon sx={{ fontSize: "9vw", color: "red" }} />
            <Typography variant="h5">{"Error en pago"}</Typography>
          </Box>
        );
        break;
      default:
        break;
    }
    return result;
  };

  const renderPage = () => {
    let view = renderView();
    if (user.identifier) {
      return (
        <BaseHomePage>
          <PaperBaseComponent sx={{ m: 2 }}>{view}</PaperBaseComponent>
        </BaseHomePage>
      );
    } else {
      return (
        <Container component="main">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              mb: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{ width: 230, height: "auto", m: 1, mb: 5 }}
              src={SweevenLogo}
            />

            {view}
          </Box>
        </Container>
      );
    }
  };

  return renderPage();
}
