import { logError } from "../helpers/Logger";
import EndPoints from "../config/EndPoints.json";
import axios from "axios";

export const getKitsService = () => {
  return new Promise(async (resolve, reject) => {
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.EnrollmentCollectionEndpoint}`;
    try {
      let response = await axios.get(endpoint);
      let result = response.data;
      if (result.code === 0) {
        resolve({ code: 0, data: result.data });
      } else {
        reject({ code: 1 });
      }
    } catch (error) {
      logError(`ERROR: ${error}`);
      reject({ code: 1, error: error });
    }
  });
};
