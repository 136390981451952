import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Container,
  CssBaseline,
} from "@mui/material";

import { useTranslation } from "react-i18next";

export default function CaptureContactComponent(props) {
  const { t } = useTranslation();
  const [data, setData] = useState({
    email: props.email,
  });

  const handleChangeText = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onComplete("contact", data);
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Box
        sx={{
          m: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="email"
                id="email"
                type="email"
                value={data.email}
                label={t("email")}
                onChange={handleChangeText}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="phone"
                id="phone"
                type="tel"
                label={t("phone")}
                onChange={handleChangeText}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth type="submit" variant="contained">
                {t("next")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
