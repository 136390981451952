import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "../../ColorsConfig";
import { LoadingButton } from "@mui/lab";

export default function UIButton(props) {
  return (
    <LoadingButton sx={{ mt: 2, mb: 1 }} {...props}>
      {props.title}
    </LoadingButton>
  );
}
