/**
 * AddressesAPIService.jsx
 */
import axios from "axios";
import { store } from "../store/store";
import EndPoints from "../config/EndPoints.json";
import { log, logError } from "../helpers/Logger";
import { AddressesType } from "../types/AddressesType";
import { BaseViewType } from "../types/BaseViewType";

export const getAddressesService = (userIdentifier) => {
  return new Promise(async (resolve, reject) => {
    const state = store.getState();
    const token = state.auth.token;
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.AddressesEndpoint}`;
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          dealerIdentifier: userIdentifier,
        },
      });
      let result = response.data;
      if (result.code === 0) {
        resolve({ code: 0, data: result.data });
      } else {
        reject({ code: 1 });
      }
    } catch (error) {
      logError(error);
      reject({ code: 1, error: error });
    }
  });
};

export const getAddressesAction = (userIdentifier) => {
  return async (dispatch) => {
    const state = store.getState();
    const token = state.auth.token;
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.AddressesEndpoint}`;
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          userIdentifier,
        },
      });
      let result = response.data;
      if (result.code === 0) {
        dispatch({ type: AddressesType.setAddresses, payload: result.data });
      } else {
        dispatch({ type: AddressesType.setAddresses, payload: [] });
      }
    } catch (error) {
      logError(error);
    }
  };
};

export const createAddressesAction = (address) => {
  return async (dispatch) => {
    dispatch({ type: AddressesType.setLoading, payload: true });
    const state = store.getState();
    const token = state.auth.token;
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.AddressesEndpoint}`;
    try {
      let response = await axios.post(endpoint, address, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      log(response);
      let result = response.data;
      if (result.code === 0) {
        dispatch({
          type: AddressesType.addressCreated,
          payload: { ...address, identifier: response.data },
        });
      } else {
        //TODO: DISPATCH ERROR
        logError("ds");
        logError(result);
        dispatch({ type: AddressesType.setLoading, payload: Boolean() });
      }
    } catch (error) {
      //TODO: DISPATCH ERROR
      logError(error);
      dispatch({ type: AddressesType.setLoading, payload: Boolean() });
    }
  };
};

export const deleteAddressesAction = (identifier) => {
  return async (dispatch) => {
    dispatch({ type: AddressesType.setLoading, payload: true });
    const state = store.getState();
    const token = state.auth.token;
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.AddressesEndpoint}`;
    try {
      let response = await axios.delete(`${endpoint}/${identifier}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let result = response.data;
      if (result.code === 0) {
        dispatch({
          type: AddressesType.addressDeleted,
          payload: identifier,
        });
        dispatch({
          type: BaseViewType.showAlert,
          payload: {
            title: "",
            message: "Dirección eliminada",
          },
        });
      } else {
        dispatch({
          type: AddressesType.error,
          payload: { error: result.error },
        });
      }
    } catch (error) {
      logError(`ERROR: ${error}`);
      dispatch({
        type: AddressesType.error,
        payload: { error },
      });
    }
  };
};

export const updateAddressesAction = (address) => {
  return async (dispatch) => {
    dispatch({ type: AddressesType.setLoading, payload: true });
    const state = store.getState();
    const token = state.auth.token;
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.AddressesEndpoint}`;
    try {
      let response = await axios.put(endpoint, address, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let result = response.data;
      if (result.code === 0) {
        dispatch({
          type: AddressesType.addressUpdated,
          payload: { ...address },
        });
      } else {
        logError(result);
        dispatch({ type: AddressesType.setLoading, payload: Boolean() });
      }
    } catch (error) {
      dispatch({ type: AddressesType.setLoading, payload: Boolean() });
      logError(error);
    }
  };
};
