import React from "react";
import { Routes, Route } from "react-router-dom";
import GenealogyPage from "../pages/GenealogyPage";

const GenealogyRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<GenealogyPage />} />
    </Routes>
  );
};

export default GenealogyRouter;
