import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Grid,
  CircularProgress,
  Typography,
  CardHeader,
  Card,
  Fade,
  Box,
  CardActionArea,
  IconButton,
} from "@mui/material";
import UIButton from "../../components/general/UIButton";
import { getAddressesAction } from "../../APIServices/AddressesAPIService";
import { findCountry, findState } from "../../helpers/FindCountry";
import { AddressViewComponent } from "./AddressViewComponent";
import DialogModal from "./DialogModal";
import {
  createAddressesAction,
  deleteAddressesAction,
  updateAddressesAction,
} from "../../APIServices/AddressesAPIService";
import { AddressesType } from "../../types/AddressesType";

import EditIcon from "@mui/icons-material/Edit";
import AlertDialogComponent from "./AlertDialogComponent";
import { log, logError } from "../../helpers/Logger";

export const AddressesListComponent = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const internalRef = useRef();
  const [editMode, setEditMode] = useState(props.editMode);
  const [editAddress, setEditAddress] = useState(props.editAddress);
  const [showAlert, setShowAlert] = useState(Boolean());
  const [addressSelected, setAddressSelected] = useState(null);
  const [viewIdentifier, setViewIdentifier] = useState("shippingAddress");
  const addresses = useSelector((state) => state.addresses.addresses);
  const addressData = useSelector((state) => state.addresses);
  const identifier = useSelector((state) => state.auth.id);
  const user = useSelector((state) => state.user);

  useImperativeHandle(ref, () => ({
    onClick() {
      document.getElementById("userForm").submitButton.click();
    },
  }));

  useEffect(() => {
    if (addresses === undefined || addresses === null) {
      dispatch(getAddressesAction(identifier));
    }
    if (props.viewIdentifier && props.viewIdentifier !== viewIdentifier) {
      setViewIdentifier(props.viewIdentifier);
    }
  }, [
    addresses,
    identifier,
    props.viewIdentifier,
    dispatch,
    editMode,
    props.editMode,
    viewIdentifier,
  ]);

  const deleteAddressToServer = useCallback(async () => {
    try {
      dispatch(deleteAddressesAction(addressSelected.identifier));
      setAddressSelected(null);
      setEditAddress(Boolean());
    } catch {}
  }, [addressSelected, dispatch]);

  const createAddressService = useCallback(
    async (address) => {
      dispatch(
        createAddressesAction({
          ...address,
          userIdentifier: user.identifier,
        })
      );
    },
    [user.identifier, dispatch]
  );

  const updateAddressService = useCallback(
    async (address) => {
      dispatch(updateAddressesAction(address));
    },
    [dispatch]
  );

  const handleDialogOnClose = () => {
    setEditAddress(Boolean());
    setAddressSelected(null);
    dispatch({ type: AddressesType.setOpenDialog, payload: false });
  };

  const handleDialogAccept = (address) => {
    setEditAddress(Boolean());
    setAddressSelected(null);
    internalRef.current.onClick();
    // dispatch(createAddressesAction());
  };

  const renderAddresses = () => {
    if (addresses) {
      let duration = Number();
      return addresses.map((address) => {
        duration += 300;
        let state = String();
        let country = findCountry(address.country);
        if (country === undefined) {
          country = String();
        } else {
          let getState = findState(address.state, country.provinces);
          if (state !== undefined) {
            state = getState;
            state = state.name;
          }
          country = country.name;
        }

        return (
          <Grid item key={address.identifier}>
            <Fade in {...{ timeout: duration }}>
              {
                <Card
                  variant="outlined"
                  onClick={() => {
                    // handleSelectedAddress(viewIdentifier, address);
                    if (props.editMode) {
                      setAddressSelected(address);
                      setEditAddress(true);
                      dispatch({
                        type: AddressesType.editAddress,
                        payload: address,
                      });
                    } else {
                      dispatch({
                        type: props.dispatch,
                        payload: {
                          name: viewIdentifier,
                          value: address,
                        },
                      });
                    }
                  }}
                  sx={{
                    background:
                      props.selectedItem === address.identifier
                        ? "#EEE"
                        : "transparent",
                  }}
                >
                  <CardActionArea>
                    <CardHeader
                      action={
                        props.editMode && (
                          <IconButton aria-label="settings">
                            <EditIcon />
                          </IconButton>
                        )
                      }
                      title={`${address.alias}`}
                      subheader={
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            {`${address.address1}`}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {`${country}, ${state}`}
                          </Typography>
                        </Box>
                      }
                    />
                  </CardActionArea>
                </Card>
              }
            </Fade>
          </Grid>
        );
      });
    } else {
      return (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      );
    }
  };

  const renderView = () => {
    return (
      <Box sx={{ mt: 1 }}>
        <Grid container spacing={1} sx={{ m: 1 }}>
          <Grid item xs={12}>
            <Typography variant="h3">{props.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{props.subtitle}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {renderAddresses()}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <UIButton
              variant="outlined"
              title={t("addAddress")}
              onClick={() => {
                dispatch({ type: AddressesType.setOpenDialog, payload: true });
              }}
            />
          </Grid>
        </Grid>
        <DialogModal
          title={editAddress ? t("editAddress") : t("addAddress")}
          accept={t("saveAddress")}
          open={addressData.openDialog}
          loading={addressData.loading}
          onClose={handleDialogOnClose}
          onAccept={handleDialogAccept}
        >
          <AddressViewComponent
            ref={internalRef}
            billing={false}
            onComplete={(identifier, data) => {
              if (editAddress) {
                updateAddressService(data);
              } else {
                createAddressService(data);
              }
            }}
            customKey={"shippingAddress"}
            userInfo={null}
            title={String()}
            address={addressSelected}
            editMode={editMode}
          />
          {editAddress && (
            <UIButton
              variant="outlined"
              title={t("delete")}
              sx={{ width: "100%", mt: 1, color: "red", borderColor: "red" }}
              onClick={() => {
                setShowAlert(true);
              }}
            />
          )}
        </DialogModal>
        <AlertDialogComponent
          open={showAlert}
          title={t("warning")}
          message={t("wouldLikeDelete")}
          onAccept={() => {
            setShowAlert(Boolean());
            deleteAddressToServer();
          }}
          onCancel={() => {
            setShowAlert(Boolean());
          }}
        />
        <input type="submit" style={{ display: "none" }} name="submitButton" />
      </Box>
    );
  };

  return renderView();
});
