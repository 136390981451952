/**
 *
 */
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  addDoc,
  onSnapshot,
  query,
  updateDoc,
  getDocs,
  getDoc,
  writeBatch,
  orderBy,
  where,
  deleteDoc,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA-NZv5C-G8LZ3HeJocrIzeb8Yk9Iox2uA",
  authDomain: "life-sweeven-autoshipping.firebaseapp.com",
  projectId: "life-sweeven-autoshipping",
  storageBucket: "life-sweeven-autoshipping.appspot.com",
  messagingSenderId: "917762655058",
  appId: "1:917762655058:web:4f993a5dde99bdb6c8708b",
  measurementId: "G-9R9NJLJXCX",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const analytics = getAnalytics(app);

export {
  app,
  analytics,
  db,
  doc,
  auth,
  writeBatch,
  query,
  getDocs,
  getDoc,
  deleteDoc,
  orderBy,
  where,
  setDoc,
  collection,
  addDoc,
  updateDoc,
  onSnapshot,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
};
