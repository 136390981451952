import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BaseHomePage from "../../pages/General/BaseHomePage";
import {
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddressViewComponent } from "../../components/general/AddressViewComponent";
import { useNavigate } from "react-router-dom";
import PaperBaseComponent from "../../components/general/PaperBaseComponent";
import {
  getAutoshippingByIdentifierService,
  pauseAutoshippingService,
  deleteAutoshippingService,
} from "../../APIServices/AutoShippingAPIService";
import UIButton from "../../components/general/UIButton";

export default function AutoShippingDetailPage() {
  const userIdentifier = useSelector((state) => state.auth.id);
  const { t } = useTranslation();
  const navigation = useNavigate();
  // const order = location.state ? location.state.data : {};
  const [order, setOrder] = useState(null);

  const [viewData, setViewData] = useState({
    needData: true,
    loading: Boolean(),
    success: Number(),
  });

  // const navigate = useNavigate();
  const [data] = useState({
    contact: {},
    products: {},
    interval: {},
    shippingAddress: {},
    billingAddress: {},
  });

  const pauseAutoshippingInvoke = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await pauseAutoshippingService(order.identifier);
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });

    if (response.code === 0) {
      alert("Se ha pausado el autoenvío");
      navigation("/autoshipping");
    }
  }, [order, navigation]);

  const deleteAutoshippingInvoke = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });

    let response = {};
    try {
      response = await deleteAutoshippingService(order.identifier);
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });
    if (response.code === 0) {
      alert("Se ha eliminado su autoenvio");
      navigation("/autoshipping");
    }
  }, [order, navigation]);

  const getAutoshippingInvoke = useCallback(async () => {
    setViewData({
      needData: Boolean(),
      loading: true,
    });
    let response = {};
    try {
      response = await getAutoshippingByIdentifierService(userIdentifier);
    } catch (error) {}

    setViewData({
      needData: Boolean(),
      loading: Boolean(),
    });
    if (response.code === 0) {
      if (response.data.length > Number()) {
        setOrder(response.data[0]);
      }
    } else {
    }
  }, [data]);

  useEffect(() => {
    if (viewData.needData) {
      getAutoshippingInvoke();
    }
  }, [getAutoshippingInvoke, viewData]);

  const validateDate = (stringDate) => {
    let date = new Date(`${stringDate}`);
    return (
      date.getDate() === new Date().getDate() ||
      date.getDate() - 1 === new Date().getDate()
    );
  };

  const renderDetailView = () => {
    return (
      <Grid container spacing={2} m={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("order")}</Typography>
          <Divider></Divider>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }} align="center">
                    {"SKU"}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="center">
                    {t("name")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="center">
                    {t("price")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="center">
                    {t("calification")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="center">
                    {t("quantity")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.products.map((product) => (
                  <TableRow
                    key={product.sku}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {product.sku}
                    </TableCell>
                    <TableCell align="center">{product.name}</TableCell>
                    <TableCell align="center">{`${Intl.NumberFormat(
                      "es-MX"
                    ).format(product.distributorPrice)} MXN`}</TableCell>
                    <TableCell align="center">
                      {product.calificationPoints}
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{product.quantity}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={6}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              {`Fecha de inicio:`}
            </Typography>
            <Typography>{`${order.interval.startedDate}`}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontWeight: "bold" }}>{`Envio cada:`}</Typography>
            <Typography>{`${t(order.interval.receive)}`}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              {`${t("status")}:`}
            </Typography>
            <Typography>{`${t(order.status)}`}</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ fontSize: 42 }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{t("shippingAddress")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddressViewComponent
                address={order.shippingAddress}
                disabled={true}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ fontSize: 42 }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{t("billingAddress")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddressViewComponent
                address={order.billingAddress}
                disabled={true}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={6}>
          <Button
            fullWidth
            disabled={validateDate(order.interval.startedDate)}
            variant="outlined"
            onClick={() =>
              navigation("/autoshipping/update", { state: { data: order } })
            }
          >
            {t("modify")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            disabled={validateDate(order.interval.startedDate)}
            variant="outlined"
            onClick={pauseAutoshippingInvoke}
          >
            {t("pause")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            disabled={validateDate(order.interval.startedDate)}
            color="error"
            variant="outlined"
            onClick={() => {
              deleteAutoshippingInvoke();
            }}
          >
            {t("delete")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              navigation(-1);
            }}
          >
            {t("back")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderButton = () => {
    return (
      <UIButton
        variant="contained"
        title={t("createAutoshipping")}
        onClick={() => {
          navigation("/autoshipping/create");
        }}
      />
    );
  };

  const renderView = () => {
    let view = null;
    if (viewData.loading) {
      view = <CircularProgress />;
    } else {
      if (order) {
        view = renderDetailView();
      } else {
        view = renderButton();
      }
    }
    return view;
  };

  return (
    <BaseHomePage>
      <PaperBaseComponent sx={{ m: 2 }}>{renderView()}</PaperBaseComponent>
    </BaseHomePage>
  );
}
