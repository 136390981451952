import React from "react";
import { Routes, Route } from "react-router-dom";
import PromotionalLinksPage from "../pages/PromotionalLinks/PromotionalLinksPage";

const PromotionalLinksRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PromotionalLinksPage />} />
    </Routes>
  );
};

export default PromotionalLinksRouter;
