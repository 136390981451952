import React, {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";
import { getKitsService } from "../APIServices/ProductsAPIService";
import { SignUpType } from "../types/SignUpType";

export const EnrollKitsComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const signup = useSelector((state) => state.signup);
  // const [kitSelected, setKitsSelected] = useState(String);
  const [kits, setKits] = useState(null);
  const [view, setView] = useState({
    needData: true,
    loading: Boolean(),
  });

  useImperativeHandle(ref, () => ({
    onClick() {
      props.onComplete();
    },
  }));

  const getKits = useCallback(async () => {
    setView({
      needData: Boolean(),
      loading: true,
    });
    try {
      let response = await getKitsService();
      if (response.code === 0) {
        setKits(response.data);
      }
    } catch (error) {}
    setView({
      needData: Boolean(),
      loading: Boolean(),
    });
  }, [setKits]);

  useEffect(() => {
    if (view.needData) {
      getKits();
    }
  }, [view.needData, getKits]);

  const renderShowKits = () => {
    const renderKits = kits.map((kit) => {
      let border = "1px solid #000";
      let identifier = signup.starterKit.identifier;
      if (kit.identifier === identifier) {
        border = "6px solid #40B879";
      }

      return (
        <Grid key={kit.identifier} item xs={12} sm={6}>
          <Box
            sx={{ border: border, padding: 3 }}
            onClick={() => {
              // props.onChange(kit);
              // setKitsSelected(kit.identifier);
              dispatch({ type: SignUpType.handleSelectKit, payload: kit });
            }}
          >
            <Typography variant="h5">{kit.title}</Typography>
            <Box
              sx={{
                width: { xs: "80vw", sm: "40vw", md: "30vw" },
              }}
              component={"img"}
              src={kit.imageUrl}
            />
          </Box>
        </Grid>
      );
    });
    return (
      <Grid container spacing={1}>
        {renderKits}
      </Grid>
    );
  };

  const renderView = () => {
    if (kits) {
      return renderShowKits();
    }
    return <CircularProgress />;
  };

  return renderView();
});
