import {
  db,
  collection,
  addDoc,
  getDocs,
  where,
  doc,
  updateDoc,
  query as q,
} from "../firebase/firebaseConfig";

import { logError } from "../helpers/Logger";

const DB_COLLECTION_CONSTANT = "autoshipping";

// FIXME: CHANGE TO API
/**
 * @deprecated DONT USE CHAGE TO API
 */
export const createAutoshippingService = (userIdentifier, order) => {
  return new Promise(async (resolve, reject) => {
    try {
      var request = collection(db, `${DB_COLLECTION_CONSTANT}`);
      var orderReference = await addDoc(request, {
        userIdentifier: userIdentifier,
        email: order.email,
        dealerIdentifier: order.dealerIdentifier,
        products: order.products,
        shippingAddress: order.shippingAddress,
        billingAddress: order.billingAddress,
        interval: order.interval,
        createdAt: new Date(),
        modifiedAt: new Date(),
        status: "active",
      });

      resolve({ code: 0, data: orderReference });
    } catch (error) {
      reject({ code: 1, error: error });
    }
  });
};

// FIXME: CHANGE TO API
/**
 * @deprecated DONT USE CHAGE TO API
 * @param {*} email
 * @returns
 */
export const getAutoshippingService = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const query = q(
        collection(db, `${DB_COLLECTION_CONSTANT}`),
        where("email", "==", email),
        where("status", "!=", "deleted")
      );
      const querySnap = await getDocs(query);
      let aux = [];
      querySnap.forEach((doc) => {
        var data = doc.data();
        data.identifier = doc.id;
        aux.push(data);
      });
      aux = aux.map((item) => {
        return {
          ...item,
          interval: {
            ...item.interval,
            startedDate: item.interval.startedDate
              .toDate()
              .toISOString()
              .split("T")[0],
          },
        };
      });
      resolve({ code: 0, data: aux });
    } catch (error) {
      reject({ code: 1, error: error });
    }
  });
};

// FIXME: CHANGE TO API
/**
 * @deprecated DONT USE CHAGE TO API
 */
export const getAutoshippingByIdentifierService = (userIdentifier) => {
  return new Promise(async (resolve, reject) => {
    try {
      const query = q(
        collection(db, `${DB_COLLECTION_CONSTANT}`),
        where("userIdentifier", "==", userIdentifier),
        where("status", "!=", "deleted")
      );
      const querySnap = await getDocs(query);
      let aux = [];
      querySnap.forEach((doc) => {
        var data = doc.data();
        data.identifier = doc.id;
        aux.push(data);
      });
      // aux = aux.map((item) => {
      //   return {
      //     ...item,
      //     interval: {
      //       ...item.interval,
      //       startedDate: item.interval.startedDate
      //         .toDate()
      //         .toISOString()
      //         .split("T")[0],
      //     },
      //   };
      // });
      resolve({ code: 0, data: aux });
    } catch (error) {
      logError(error);
      reject({ code: 1, error: error });
    }
  });
};

// FIXME: CHANGE TO API
//
/**
 * @deprecated DONT USE CHAGE TO API
 */
export const updateAutoshippingService = (identifier, order) => {
  return new Promise(async (resolve, reject) => {
    var ref = doc(db, `${DB_COLLECTION_CONSTANT}`, identifier);
    let data = {
      email: order.email,
      phone: order.phone,
      products: order.products,
      shippingAddress: order.shippingAddress,
      billingAddress: order.billingAddress,
      interval: order.interval,
      modifiedAt: new Date(),
      status: "modified",
    };
    try {
      await updateDoc(ref, data);
      resolve({ code: 0 });
    } catch (error) {
      resolve({ code: 1, data: error });
    }
  });
};

// FIXME: CHANGE TO API
//
/**
 * @deprecated DONT USE CHAGE TO API
 */
export const pauseAutoshippingService = (identifier, order) => {
  return new Promise(async (resolve, reject) => {
    var ref = doc(db, `${DB_COLLECTION_CONSTANT}`, identifier);
    let data = {
      status: "pause",
      modifiedAt: new Date(),
    };
    try {
      await updateDoc(ref, data);
      resolve({ code: 0 });
    } catch (error) {
      logError(error);
      resolve({ code: 1, data: error });
    }
  });
};

// FIXME: CHANGE TO API
//
/**
 * @deprecated DONT USE CHAGE TO API
 */
export const deleteAutoshippingService = (identifier, order) => {
  return new Promise(async (resolve, reject) => {
    var ref = doc(db, `${DB_COLLECTION_CONSTANT}`, identifier);
    let data = {
      status: "deleted",
      modifiedAt: new Date(),
    };
    try {
      await updateDoc(ref, data);
      resolve({ code: 0 });
    } catch (error) {
      resolve({ code: 1, data: error });
    }
  });
};
