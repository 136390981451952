import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SignUpType } from "../../types/SignUpType";
import { Grid, TextField, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export const EmailValidationComponent = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email, confirmEmail } = useSelector((state) => state.signup);

  useEffect(() => {}, []);

  useImperativeHandle(ref, () => ({
    onClick() {
      document.getElementById("userForm").submitButton.click();
    },
  }));

  const handleTextChange = (e) => {
    const value = e.target.value;
    // setData({
    //   ...data,
    //   [e.target.name]: value,
    // });
    dispatch({
      type: SignUpType.handleTextChange,
      payload: {
        name: e.target.name,
        value: value,
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email.value === confirmEmail.value) {
      props.onComplete("emailValidation");
    }
  };

  return (
    <Box
      sx={{
        mt: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        id="userForm"
        component="form"
        onSubmit={handleSubmit}
        sx={{ mt: 1 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              onChange={handleTextChange}
              value={email.value}
              margin="normal"
              required
              fullWidth
              name="email"
              label={t("email")}
              type="email"
              id="email"
              autoComplete="email"
            />
          </Grid>
          {/*  */}
          <Grid item xs={12}>
            <TextField
              onChange={handleTextChange}
              value={confirmEmail.value}
              margin="normal"
              required
              fullWidth
              name="confirmEmail"
              label={t("confirmEmail")}
              type="email"
              id="confirmEmail"
            />
          </Grid>
        </Grid>

        <input type="submit" style={{ display: "none" }} name="submitButton" />
      </Box>
    </Box>
  );
});
