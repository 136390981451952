/**
 * AuthenticationAction.jsx
 */
import { log } from "../helpers/Logger";
import axios from "axios";
import EndPoints from "../config/EndPoints.json";
import { store } from "../store/store";
import { AuthTypes } from "../types/AuthTypes";
import { AddressesType } from "../types/AddressesType";
import { AutoShippingType } from "../types/AutoShippingType";
import { SignUpType } from "../types/SignUpType";
import { UserTypes } from "../types/UserTypes";
import {
  auth,
  signInWithEmailAndPassword,
  signOut,
} from "../firebase/firebaseConfig";

/**
 *
 * @param {*} id
 * @param {*} user
 * @param {*} token
 * @returns
 */
export const login = (id, user, token) => {
  return {
    type: AuthTypes.signIn,
    payload: {
      id,
      user,
      token,
    },
  };
};

/**
 *
 * @param {*} data
 * @returns
 */
export const updateInfo = (data) => {
  return {
    type: AuthTypes.updateInfo,
    payload: {
      data,
    },
  };
};

/**
 *
 * @param {*} email
 * @param {*} password
 * @returns
 */
export const signInAction = (email, password) => {
  return (dispatch) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {})
      .catch((error) => {
        dispatch({
          type: AuthTypes.error,
        });
      });
  };
};

/**
 *
 * @param {*} data
 * @returns
 */
export const signUpAction = (data) => {
  return (dispatch) => {
    // createUserWithEmailAndPassword(auth, email, password)
    //   .then(({ user }) => {
    //     updateProfile(auth.currentUser, {
    //       displayName: `${name} ${lastName}`,
    //     }).then(() => {
    //       dispatch({
    //         type: AuthTypes.nameChanged,
    //         payload: `${name} ${lastName}`,
    //       });
    //       //addUSerInDataBase(user.uid);
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
};

/**
 *
 * @returns
 */
export const signOutAction = () => {
  return (dispatch) => {
    signOut(auth).then(() => {
      dispatch({
        type: AuthTypes.signOut,
      });
      dispatch({
        type: UserTypes.clear,
      });
      dispatch({
        type: AddressesType.clear,
      });
      dispatch({
        type: AutoShippingType.clear,
      });
      dispatch({
        type: SignUpType.clear,
      });
    });
  };
};

/**
 *
 * @returns
 */
export const getUserGeneralInformationAction = () => {
  const state = store.getState();
  const token = state.auth.token;
  return async (dispatch) => {
    try {
      const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.GetUserEndpoint}`;
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: AuthTypes.updateInfo,
        payload: {
          user: response.data.data,
        },
      });
    } catch (error) {
      log(error);
    }
  };
};
