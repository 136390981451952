import React from "react";
import { useTranslation } from "react-i18next";
import BaseHomePage from "./General/BaseHomePage";
import {
  Grid,
  Box,
  IconButton,
  Typography,
  Switch,
  Stack,
} from "@mui/material";
// import { styled } from "@mui/material/styles";
// import TreeView from "@mui/lab/TreeView";
// import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PaperBaseComponent from "../components/general/PaperBaseComponent";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";

export default function UserSettingsPage(props) {
  const { t } = useTranslation();

  const renderView = () => {
    return (
      <Box id="userForm" component="form" sx={{ mt: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant={"h3"}>{t("settings")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} spacing={1}>
              <IconButton size="small">
                <MarkEmailUnreadIcon />
              </IconButton>
              <Typography sx={{ pt: 1 }}>{t("emailNotifications")}</Typography>
              <Switch />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <BaseHomePage>
      <PaperBaseComponent>{renderView()}</PaperBaseComponent>
    </BaseHomePage>
  );
}
