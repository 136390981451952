import React, { useEffect, useState } from "react";
import SweevenLogo from "../../resources/images/logo_sweeven_light.svg";
import { useSearchParams } from "react-router-dom";
import {
  CircularProgress,
  Container,
  Grid,
  Typography,
  Box,
  Link,
} from "@mui/material";
import { Wallet } from "@mercadopago/sdk-react";
import { initMercadoPago } from "@mercadopago/sdk-react";
initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);

function Copyright() {
  let env = String();
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    env = process.env.REACT_APP_ENVIRONMENT;
  }
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://sweeven.life/">
        {process.env.REACT_APP_NAME}
      </Link>
      {` ${new Date().getFullYear()}`}
      {` ${env}`}
    </Typography>
  );
}

export default function PaymentPage() {
  const [data, setData] = useState({});
  const [searchParams] = useSearchParams();
  useEffect(() => {
    setData({
      name: searchParams.get("dealerName"),
      sponsor: searchParams.get("sponsor"),
      preference: searchParams.get("preference"),
    });
  }, [searchParams]);

  const renderView = () => {
    if (data.preference) {
      return (
        <Grid container sx={{ m: "auto" }}>
          <Grid item xs={12}>
            <Wallet initialization={{ preferenceId: data.preference }} />
          </Grid>
        </Grid>
      );
    } else {
      return <CircularProgress />;
    }
  };

  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{ width: 230, height: "auto", m: 1 }}
          src={SweevenLogo}
        />
        <Typography variant="h1">{`Hola ${data.name}!`}</Typography>
        <Typography variant="h3">{`estas a un paso de completar tu registro`}</Typography>
        <Typography variant="h3">{`${data.sponsor} te manda tu link de pago`}</Typography>
        {renderView()}
      </Box>
      <Box component="footer" sx={{ p: 2 }}>
        <Copyright />
      </Box>
    </Container>
  );
  // <Container component="main">{renderView()}</Container>;
}
