import { log } from "../helpers/Logger";
import { AddressesType } from "../types/AddressesType";

let INITIAL_STATE = {
  loading: Boolean(),
  openDialog: Boolean(),
};

export const AddressesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AddressesType.setAddresses:
      return {
        ...state,
        addresses: action.payload,
      };

    case AddressesType.addressCreated:
      let addresses = [];
      if (state.addresses !== undefined) {
        addresses = state.addresses.concat(action.payload);
      } else {
        addresses.push(action.payload);
      }
      return {
        ...state,
        loading: Boolean(),
        openDialog: Boolean(),
        addresses,
      };

    case AddressesType.setLoading:
      return {
        ...state,
        loading: action.payload,
      };

    case AddressesType.addressUpdated:
      return {
        ...state,
        loading: Boolean(),
        openDialog: Boolean(),
      };

    case AddressesType.addressDeleted:
      return {
        ...state,
        addresses: state.addresses.filter(
          (add) => add.identifier !== action.payload
        ),
        openDialog: Boolean(),
        loading: Boolean(),
      };

    case AddressesType.editAddress:
      return {
        ...state,
        selectedAddress: action.payload,
        openDialog: true,
      };

    case AddressesType.setOpenDialog:
      return {
        ...state,
        openDialog: action.payload,
      };

    case AddressesType.clear:
      return INITIAL_STATE;

    default:
      return state;
  }
};
