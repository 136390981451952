import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Typography, Link } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Header from "../../components/general/Header";
import Navigator from "../../components/general/Navigator";
import theme from "../../ColorsConfig";

const drawerWidth = 256;

function Copyright() {
  let env = String();
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    env = process.env.REACT_APP_ENVIRONMENT;
  }
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://sweeven.life/">
        {process.env.REACT_APP_NAME}
      </Link>
      {` ${new Date().getFullYear()}`}
      {` ${env}`}
    </Typography>
  );
}

export default function BaseHomePage(props) {
  //   const [content] = props.content;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [title, setTitle] = useState("?????");

  useEffect(() => {
    if (props.title) {
      setTitle(props.title);
    }
  }, [props.title]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {isSmUp ? null : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          )}

          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: "block", xs: "none" } }}
          />
        </Box>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Header onDrawerToggle={handleDrawerToggle} title={title} />
          <Box
            component="main"
            sx={{
              flex: 1,
              py: 6,
              px: 4,
              bgcolor: "#eaeff1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {props.children}
          </Box>
          <Box component="footer" sx={{ p: 2, bgcolor: "#eaeff1" }}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
