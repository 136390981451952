import React from "react";
import { Routes, Route } from "react-router-dom";
import DealerDetailPage from "../pages/DealerDetailPage";
import UserSettingsPage from "../pages/UserSettingsPage";
const DealerRouter = (props) => {
  return (
    <Routes>
      <Route path="/detail" element={<DealerDetailPage />} />
      <Route path="/settings" element={<UserSettingsPage />} />
    </Routes>
  );
};

export default DealerRouter;
