/**
 * UserAPIService.jsx
 */
import axios from "axios";
import { store } from "../store/store";
import { db, doc, getDoc } from "../firebase/firebaseConfig";
import EndPoints from "../config/EndPoints.json";
import { logError } from "../helpers/Logger";

const DB_COLLECTION_CONSTANT = "users";

/**
 *
 * @param {*} userIdentifier
 * @returns
 */
export const getUserService = (userIdentifier) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, DB_COLLECTION_CONSTANT, userIdentifier);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const newData = docSnap.data();
        newData.enrollDate = newData.enrollDate;
        resolve({ code: 0, data: newData });
      } else {
        // doc.data() will be undefined in this case
        reject({ code: 1, error: "NOT_DOCUMENT" });
      }
    } catch (error) {
      logError(`ERROR: ${error}`);
      reject({ code: 1, error: error });
    }
  });
};

/**
 *
 * @param {*} dealerIdentifier
 * @returns
 */
export const getNetworkDealerService = (dealerIdentifier) => {
  return new Promise(async (resolve, reject) => {
    const state = store.getState();
    const token = state.auth.token;
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.getGenealogyEndpoint}`;
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          dealerIdentifier: dealerIdentifier,
        },
      });
      let result = response.data;
      if (result.code === 0) {
        resolve({ code: 0, data: result.data });
      } else {
        reject({ code: 1 });
      }
    } catch (error) {
      logError(`ERROR: ${error}`);
      reject({ code: 1, error: error });
    }
  });
};

/**
 *
 * @param {*} data
 * @returns
 */
export const registerNewDealerService = (data, type = Number()) => {
  return new Promise(async (resolve, reject) => {
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.enrollDealerEndpoint}`;
    try {
      let response = await axios.post(endpoint, {
        ...data,
      });
      let result = response.data;
      if (result.code === 0) {
        resolve({ code: 0, data: result.data });
      } else {
        reject({ code: 1 });
      }
    } catch (error) {
      logError(`ERROR: ${error}`);
      reject({ code: 1, error: error });
    }
  });
};

/**
 *
 * @param {*} url
 * @returns
 */
export const validateUrlService = (url) => {
  return new Promise(async (resolve, reject) => {
    const state = store.getState();
    const token = state.auth.token;
    try {
      const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.validateUrlEnpoint}`;
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          dealerUrl: url,
        },
      });
      resolve(response.data);
    } catch (error) {
      logError(`ERROR: ${error}`);
      reject({ code: 1, error: error });
    }
  });
};

/**
 *
 * @param {*} data
 * @returns
 */
export const validateEmailService = (data) => {
  return new Promise(async (resolve, reject) => {
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.validateEmail}`;
    try {
      let response = await axios.post(endpoint, {
        email: data,
      });
      resolve(response.data);
    } catch (error) {
      logError(`ERROR: ${error}`);
      reject({ code: 1, error: error });
    }
  });
};

/**
 *
 * @param {object} data Data for get user adn set payment
 * @returns Promise
 */
export const completePaymentService = (data) => {
  return new Promise(async (resolve, reject) => {
    const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.completePaymentEnpoint}`;
    try {
      let response = await axios.post(endpoint, data);
      resolve(response.data);
    } catch (error) {
      logError(`ERROR: ${error}`);
      reject({ code: 1, error: error });
    }
  });
};
